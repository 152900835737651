import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/UI/Button/Button';
import UserHobbiesData from '../../../AllMatches/UserProfile/UserHobbiesData';
import UserEducationData from '../../../AllMatches/UserProfile/UserEducationData';
import AboutFamily from '../../../AllMatches/UserProfile/AboutFamily';
import UserPersonalData from '../../../AllMatches/UserProfile/UserPersonalData';
import UserFamilyData from '../../../AllMatches/UserProfile/UserFamilyData';
import UserGalleryData from '../../../AllMatches/UserProfile/UserGalleryData';
import UserContactData from '../../../AllMatches/UserProfile/UserContactData';
import UserProfilePictureData from '../../../AllMatches/UserProfile/UserProfilePictureData';
import UserAboutData from '../../../AllMatches/UserProfile/UserAboutData';
import { useEffect, useState } from 'react';
import {
  fetchPhotoAlbumByProfileId,
  fetchProfilePictureByProfileId,
} from '../../../../redux/features/imageSlice';
// import { getUserProfile } from '../../../../redux/features/userSlice';

const ProfileLooksLike = (props) => {
  const dispatch = useDispatch();
  const [profilePicture, setProfilePicture] = useState(null);
  const [photoAlbum, setPhotoAlbum] = useState(null);
  const user = useSelector((state) => state.user.userInfo);
  const additionalDetails = useSelector(
    (state) => state.user.additionalDetails
  );

  // useEffect(() => {
  //   console.log('Updated User Info: ', user);
  // }, [user]);

  const { email, profile_id } = user || {};
  const {
    occupation_detail,
    height_ft,
    height_inch,
    dob,
    city,
    first_name,
    last_name,
    about_myself,

    mobile,
    address,

    father_name,
    mother_name,
    father_status_occupation,
    mother_status_occupation,
    number_of_siblings,
    family_type,
    family_city,
    family_values,
    family_country,
    family_state,
    family_pincode,
    siblings_brother,
    siblings_sister,
    siblings_brother_married,
    siblings_sister_married,

    sub_community,
    weight,
    weight_measure,
    blood_group,
    marital_status,
    mother_tongue,
    religion,
    community,
    gothra,
    physically_status,
    complexion,
    time_of_birth,
    place_of_birth,
    manglic_status,
    raashi,

    about_my_family,

    highest_qualification,
    institute_name,
    current_income,
    occupation_type,

    hobbies,
    dietary_preferences,
  } = additionalDetails || {};

  // console.log(profile_id);
  useEffect(() => {
    if (profile_id) {
      const fetchPicture = async () => {
        const picResponse = await dispatch(
          fetchProfilePictureByProfileId(profile_id)
        );
        const albumResponse = await dispatch(
          fetchPhotoAlbumByProfileId(profile_id)
        );
        // console.log(picResponse?.payload);
        // console.log(albumResponse?.payload);
        if (picResponse?.payload) {
          setProfilePicture(picResponse?.payload?.profile_picture);
        }
        if (albumResponse?.payload) {
          setPhotoAlbum(albumResponse?.payload?.photo_album);
        }
      };
      fetchPicture();
    }
  }, [dispatch, profile_id]);

  // console.log(profilePicture);

  return (
    <>
      {/* <Navbar onShowSignUp={props.onShowSignUp} /> */}
      <section className='flex justify-center items-center w-full bg-[#FFFFF4] pb-32 pt-6 md:pt-6 lg:pt-10 mb-6 px-2 md:px-6 lg:px-10 mt-20'>
        <div className='flex flex-col justify-center items-center w-full p-4 border-2 border-[#FFC0CB] rounded-lg'>
          <div className='flex flex-col justify-center items-center w-full mb-10 bg-white'>
            <p className='font-bold text-[#FF4061] text-[38px] lg:text-[52px] my-2 text-center'>
              Profile
            </p>
            <p className='font-semibold text-[#818181] text-[16px] md:text-[18px] lg:text-[24px] my-2 text-center'>
              Most Trusted and premium Matrimony Service in the World.
            </p>
          </div>
          <div className='flex flex-col justify-center items-center w-full bg-white'>
            <div className='flex flex-col md:flex-col lg:flex-row justify-between items-start w-full md:w-[98%]'>
              <UserProfilePictureData profilePicture={profilePicture} />
              <UserAboutData
                occupation_detail={occupation_detail}
                height_ft={height_ft}
                height_inch={height_inch}
                dob={dob}
                city={city}
                first_name={first_name}
                last_name={last_name}
                about_myself={about_myself}
                profile_id={profile_id}
              />
            </div>
            <div className='flex flex-col md:flex-row justify-between items-start w-[98%] border-b-'>
              <UserContactData
                email={email}
                mobile={mobile}
                address={address}
              />
              <UserGalleryData photoAlbumData={photoAlbum} />
            </div>
            <div className='flex flex-col justify-center items-center w-[98%] border-b-'>
              <div className='flex flex-col md:flex-row justify-between items-start w-full'>
                <UserFamilyData
                  father_name={father_name}
                  mother_name={mother_name}
                  father_status_occupation={father_status_occupation}
                  mother_status_occupation={mother_status_occupation}
                  number_of_siblings={number_of_siblings}
                  family_type={family_type}
                  family_city={family_city}
                  family_values={family_values}
                  family_country={family_country}
                  family_state={family_state}
                  family_pincode={family_pincode}
                  siblings_brother={siblings_brother}
                  siblings_sister={siblings_sister}
                  siblings_brother_married={siblings_brother_married}
                  siblings_sister_married={siblings_sister_married}
                />
                <UserPersonalData
                  first_name={first_name}
                  last_name={last_name}
                  dob={dob}
                  sub_community={sub_community}
                  height_ft={height_ft}
                  height_inch={height_inch}
                  weight={weight}
                  weight_measure={weight_measure}
                  blood_group={blood_group}
                  marital_status={marital_status}
                  mother_tongue={mother_tongue}
                  religion={religion}
                  community={community}
                  gothra={gothra}
                  dietary_preferences={dietary_preferences}
                  physically_status={physically_status}
                  complexion={complexion}
                  time_of_birth={time_of_birth}
                  place_of_birth={place_of_birth}
                  manglic_status={manglic_status}
                  raashi={raashi}
                />
              </div>
              <div className='flex justify-center items-center w-full pb-10'>
                <AboutFamily aboutText={about_my_family || ''} />
              </div>
            </div>
            {/* <div className='flex flex-col justify-center items-center w-[98%] border-b-2'>
              <div className='flex flex-col md:flex-row justify-between items-start w-full'>
                <UserFamilyData />
                <UserPersonalData />
              </div>
              <div className='flex justify-center items-center w-full pb-10'>
                <AboutFamily aboutText={aboutText} />
              </div>
            </div> */}

            <div className='flex flex-col lg:flex-row justify-between items-start w-[98%]'>
              <UserEducationData
                highest_qualification={highest_qualification}
                institute_name={institute_name}
                current_income={current_income}
                occupation_type={occupation_type}
                occupation_detail={occupation_detail}
              />
              <UserHobbiesData hobbies={hobbies} />
            </div>
            <div className='flex justify-center items-center w-[98%]'>
              <Button
                text='Match Now'
                className='w-[80%] md:w-[50%] !h-[55px] font-bold'
                bgBtn='bg-[#333333]'
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProfileLooksLike;

// <div className='flex flex-col justify-center items-center w-full bg-white'>
//   <div className='flex flex-col lg:flex-row justify-between items-start w-[98%]'>
//     <UserProfilePictureData />
//     <UserAboutData />
//   </div>
//   <div className='flex flex-col md:flex-row justify-between items-start w-[98%] border-b-'>
//     <UserContactData />
//     <UserGalleryData />
//   </div>
//   <div className='flex flex-col justify-center items-center w-[98%] border-b-'>
//     <div className='flex flex-col md:flex-row justify-between md:gap-4 lg:gap-0 items-start w-full'>
//       <UserFamilyData />
//       <UserPersonalData />
//     </div>
//     <div className='flex justify-center items-center w-full pb-10'>
//       <AboutFamily aboutText={about_my_family || ''} />
//     </div>
//   </div>
//   <div className='flex flex-col justify-center items-center w-[98%] border-b-2'>
//     <div className='flex flex-col md:flex-row justify-between items-start w-full'>
//       <UserFamilyData />
//       <UserPersonalData />
//     </div>
//     <div className='flex justify-center items-center w-full pb-10'>
//       <AboutFamily aboutText={aboutText} />
//     </div>
//   </div>

//   <div className='flex flex-col lg:flex-row justify-between items-start w-[98%]'>
//     <UserEducationData />
//     <UserHobbiesData />
//   </div>
//   <div className='flex justify-center items-center w-[98%]'>
//     <Button
//       text='Match Now'
//       className='w-[80%] md:w-[50%] !h-[55px] font-bold'
//       bgBtn='bg-[#333333]'
//     />
//   </div>
// </div>

// import Button from '../../../../components/UI/Button/Button';
// import DashboardLayout from '../../DashboardLayout';
// import AboutSection from './AboutSection';
// import BasicInfoSection from './BasicInfoSection';
// import PhotoGallerySection from './PhotoGallerySection';
// import ContactInfoSection from './ContactInfoSection';
// import PersonalInfoSection from './PersonalInfoSection';
// import EducationalBackgroundSection from './EducationalBackgroundSection';
// import FamilyInfoSection from './FamilyInfoSection';
// import HobbiesInterestSection from './HobbiesInterestSection';
// import SocialMediaSection from './SocialMediaSection';
// import { useSelector } from 'react-redux';

// const ProfileLooksLike = (props) => {
//   const user = useSelector((state) => state.user.userInfo);
//   console.log(user);
//   console.log('hello');

//   return (
//     <DashboardLayout activePage='profilelookslike'>
//       <div className='flex flex-col justify-center items-start w-full'>
//         <div className='flex justify-start items-center w-full'>
//           <p className='font-extrabold text-[26px] text-[#FF4061]'>User Name</p>
//         </div>
//         <span className='bg-[#CCCCCC] w-full h-[1px] my-3'></span>
//         <div className='flex flex-col justify-center items-center w-full min-h-[540px] max-h-[540px]'>
//           <div className='flex justify-center items-center bg-[rd] h-[550px] w-full overflow-hidden bg-[red]'>
//             <img
//               src='https://img.freepik.com/free-photo/young-bearded-man-with-striped-shirt_273609-5677.jpg?t=st=1718176120~exp=1718179720~hmac=b7f319731820e439f10b6a56cef5152bee069537cf7935a6d17d5575ea36e3f8&w=996'
//               alt='profile-pic'
//               className='w-full'
//             />
//           </div>
//           <div className='flex justify-between items-center w-full'>
//             <Button
//               text='Chat Now'
//               className='w-[50%] rounded-none !bg-[#333333ff] py-6 font-bold'
//             />
//             <Button
//               text='Match Now'
//               className='w-[50%] rounded-none py-6 font-extrabold'
//             />
//           </div>
//         </div>
//       </div>

//       <div className='flex flex-col justify-center items-center border-2 border-[#FFC0CB] rounded-lg py-4 w-[95%] mb-20 bg-[#FFFFFF]'>
//         <div className='flex justify-center items-center w-[95%]'>
//           <ul className='flex flex-col justify-center items-start w-full pb-4'>
//             <AboutSection />
//             <BasicInfoSection />
//             <PhotoGallerySection />
//             <ContactInfoSection />
//             <PersonalInfoSection />
//             <EducationalBackgroundSection />
//             <FamilyInfoSection />
//             <HobbiesInterestSection />
//             <SocialMediaSection />
//           </ul>
//         </div>
//       </div>
//     </DashboardLayout>
//   );
// };

// export default ProfileLooksLike;
