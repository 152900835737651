import React, { useState } from 'react';
import Modal from '../../components/UI/Modal/Modal';
import { IoCloseSharp } from 'react-icons/io5';
import SignUpStep1 from './SignUpStep1';
import SignUpStep2 from './SignUpStep2';
import SignUpStep3 from './SignUpStep3';
import SignUpStep4 from './SignUpStep4';
import { useSelector } from 'react-redux';

const Signup = (props) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({});
  // console.log('formData: ', formData);

  const {
    country,
    states,
    city,
    religion,
    community,
    subCommunity,
    motherTounge,
    weight,
    qualificationType,
    highestQualification,
    occupation,
    annualIncome,
    status,
    error,
  } = useSelector((state) => state.field);

  if (status === 'failed') return <p>Error: {error}</p>;

  const religionArray = religion.map((religion, idx) => {
    return { name: religion.name, id: religion.id ? religion.id : idx };
  });
  const communityArray = community.map((community, idx) => {
    return { name: community.name, id: community.id ? community.id : idx };
  });
  const subCommunityArray = subCommunity.map((subcom, idx) => {
    return { name: subcom.name, id: subcom.id ? subcom.id : idx };
  });
  const motherToungeArray = motherTounge.map((mothertounge, idx) => {
    return {
      name: mothertounge.name,
      id: mothertounge.id ? mothertounge.id : idx,
    };
  });

  const annualIncomeArray = annualIncome.map((anIn, idx) => {
    return {
      value: anIn.value,
      name: anIn.desc,
      id: anIn.id ? anIn.id : idx,
    };
  });
  const occupationArray = occupation.map((occupa, idx) => {
    return {
      name: occupa.name,
      id: occupa.id ? occupa.id : idx,
    };
  });

  const handleNextStep = (data) => {
    setFormData((prevData) => ({ ...prevData, ...data }));
    setCurrentStep((prevStep) => prevStep + 1);
  };
  // console.log('currentStep: ', currentStep);

  // const handleNextStep = (data) => {
  //   setFormData((prevData) => ({ ...prevData, [`step${currentStep}`]: data }));
  //   setCurrentStep((prevStep) => prevStep + 1);
  // };
  // console.log('currentStep: ', currentStep);

  const handlePrevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <SignUpStep1 onNext={handleNextStep} />;
      case 2:
        return (
          <SignUpStep2
            onNext={handleNextStep}
            onPrev={handlePrevStep}
            weight={weight}
          />
        );
      case 3:
        return (
          <SignUpStep3
            onNext={handleNextStep}
            onPrev={handlePrevStep}
            religionArray={religionArray && religionArray}
            communityArray={communityArray && communityArray}
            subCommunityArray={subCommunityArray}
            motherTongueArray={motherToungeArray && motherToungeArray}
            qualificationType={qualificationType}
            highestQualification={highestQualification}
            occupationArray={occupationArray}
            annualIncomeArray={annualIncomeArray}
          />
        );
      case 4:
        return (
          <SignUpStep4
            onPrev={handlePrevStep}
            onShowSuccesMsgText={props.onShowSuccessMessage}
            states={states}
            country={country}
            city={city}
            signupFormData={formData}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal onHidePopup={props.onHideSignup}>
      <div className='flex flex-col justify-center items-center relative py-3 md:p-4 w-full lg:min-w-full'>
        <div className='flex justify-center items-center w-full'>
          <ul className='flex justify-center items-center w-full mb-2'>
            {[1, 2, 3, 4].map((step) => (
              <li key={step} className='flex justify-center items-center'>
                <span
                  className={`flex justify-center items-center ${
                    currentStep >= step ? 'bg-[#FFC0CB]' : 'bg-[#333333]'
                  } text-[#FFFFFF] font-semibold text-[24px] w-[30px] h-[30px] md:w-[40px] lg:w-[50px] md:h-[40px] lg:h-[50px] rounded-full`}
                >
                  {step}
                </span>
                {step < 4 && (
                  <span
                    className={`flex justify-center items-center w-[30px] md:w-[70px] lg:w-[100px] h-[2px] ${
                      currentStep > step ? 'bg-[#FFC0CB]' : 'bg-[#333333]'
                    }`}
                  ></span>
                )}
              </li>
            ))}
          </ul>
          <IoCloseSharp
            onClick={props.onHideSignup}
            className='absolute active:text-[#333333] text-[#333333] hover:text-[#FF4061] cursor-pointer -right-1 -top-1 -md:right-2 -md:top-2'
            size={28}
          />
        </div>

        <div className='flex flex-col justify-start items-center w-full'>
          <div className='flex flex-col justify-center items-center w-full'>
            <h2 className='font-extrabold text-[26px] md:text-[32px] text-[#333333] text-center'>
              Sign up to Matrimony
            </h2>
            <p className='font-medium text-[16px] text-[#333333]'>
              Already a member?
              <button
                onClick={props.onShowSignIn}
                className='text-[#66B6FF] ml-2 hover:underline'
              >
                Sign in
              </button>
            </p>
          </div>
          {renderStep()}
        </div>
      </div>
    </Modal>
  );
};

export default Signup;
