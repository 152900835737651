// export const dietaryPreferencesOptions = [
//   { name: 'Vegetarian' },
//   { name: 'Non-Vegetarian' },
//   { name: 'Vegan' },
//   { name: 'Eggetarian' },
//   { name: 'Pescatarian' },
//   { name: 'Jain Vegetarian' },
//   { name: 'Lacto Vegetarian' },
//   { name: 'Ovo Vegetarian' },
//   { name: 'Lacto-Ovo Vegetarian' },
//   { name: 'Fruitarian' },
//   { name: 'Gluten-Free' },
//   { name: 'Ketogenic' },
//   { name: 'Paleo' },
//   { name: 'Raw Vegan' },
//   { name: 'Low Carb' },
//   { name: 'Low Fat' },
//   { name: 'Organic' },
//   { name: 'Halal' },
//   { name: 'Kosher' },
//   { name: 'Other' },
// ];

export const hobbiesOptions = [
  { name: 'Reading' },
  { name: 'Writing' },
  { name: 'Drawing/Painting' },
  { name: 'Photography' },
  { name: 'Music (Listening)' },
  { name: 'Music (Playing Instrument)' },
  { name: 'Singing' },
  { name: 'Dancing' },
  { name: 'Cooking' },
  { name: 'Baking' },
  { name: 'Gardening' },
  { name: 'Traveling' },
  { name: 'Hiking' },
  { name: 'Camping' },
  { name: 'Sports (Playing)' },
  { name: 'Sports (Watching)' },
  { name: 'Fitness/Exercise' },
  { name: 'Yoga' },
  { name: 'Meditation' },
  { name: 'Volunteering' },
  { name: 'Learning New Skills' },
  { name: 'DIY Projects' },
  { name: 'Watching Movies/TV Shows' },
  { name: 'Gaming' },
  { name: 'Collecting (Stamps, Coins, etc.)' },
  { name: 'Fashion/Shopping' },
  { name: 'Foodie/Culinary Exploration' },
  { name: 'Pets/Animal Care' },
  { name: 'Socializing/Networking' },
  { name: 'Outdoor Activities' },
  { name: 'Adventure Sports' },
  { name: 'Art and Crafts' },
  { name: 'Writing Poetry/Prose' },
  { name: 'Fishing' },
  { name: 'Cycling' },
  { name: 'Photography' },
  { name: 'Birdwatching' },
  { name: 'Calligraphy' },
  { name: 'Astrology' },
  { name: 'Board Games/Card Games' },
  { name: 'Reading Literature' },
  { name: 'Cultural Events' },
  { name: 'Languages' },
  { name: 'Technology/Computer Science' },
  { name: 'Entrepreneurship' },
  { name: 'Financial Investments' },
  { name: 'Philanthropy' },
  { name: 'Spirituality' },
  { name: 'Environmental Conservation' },
  { name: 'Politics/Current Affairs' },
  { name: 'History' },
  { name: 'Science' },
  { name: 'Health and Wellness' },
  { name: 'Personal Development' },
  { name: 'Other' },
];

export const numOfChildren = [
  { name: 0 },
  { name: 1 },
  { name: 2 },
  { name: 3 },
];

export const colorComplexionArray = [
  { name: 'Fair' },
  { name: 'Wheatish' },
  { name: 'Dark' },
  { name: 'Very Fair' },
];

export const familyValuesArray = [
  { name: 'Traditional' },
  { name: ' Modern' },
  { name: ' Religious' },
  { name: ' Liberal' },
  { name: ' Conservative' },
  { name: ' Nuclear' },
  { name: ' Joint' },
  { name: ' Supportive' },
  { name: ' Educated' },
  { name: ' Community-Oriented' },
];

export const familyTypesArray = [
  { name: 'Nuclear' },
  { name: 'Joint' },
  { name: 'Extended' },
  { name: 'Single' },
  { name: 'Parent' },
  { name: 'Blended' },
];

export const numberOfSiblings = [
  { name: '1' },
  { name: '2' },
  { name: '3' },
  { name: '4' },
  { name: '5 or more' },
];

export const manglikStatusOptions = [{ name: 'Yes' }, { name: 'No' }];
export const havingChildrenOptions = [{ name: 'Yes' }, { name: 'No' }];

export const maritalStatusOptions = [
  { name: 'Unmarried' },
  { name: 'Divorced' },
  { name: 'Widowed' },
  { name: 'Separated' },
];
