import { useEffect, useState } from 'react';
import Button from '../../components/UI/Button/Button';
import SelectInput from '../../components/UI/SelectInput/SelectInput';
import { AiOutlineLoading } from 'react-icons/ai';
import Input from '../../components/UI/Input/Input';

const SignUpStep3 = ({
  onPrev,
  onNext,
  religionArray,
  communityArray,
  subCommunityArray,
  motherTongueArray,
  qualificationType,
  highestQualification,
  occupationArray,
  annualIncomeArray,
}) => {
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    religion: '',
    community: '',
    sub_community: '',
    mother_tongue: '',
    qualification_type: '',
    highest_qualification: '',
    occupation_type: '',
    occupation_detail: '',
    current_income: '',
  });

  const [formErrors, setFormErrors] = useState({
    religion: '',
    community: '',
    sub_community: '',
    mother_tongue: '',
    qualification_type: '',
    highest_qualification: '',
    occupation_type: '',
    occupation_detail: '',
    current_income: '',
  });

  const [touched, setTouched] = useState({
    religion: false,
    community: false,
    sub_community: false,
    mother_tongue: false,
    qualification_type: false,
    highest_qualification: false,
    occupation_type: false,
    occupation_detail: false,
    current_income: false,
  });

  const qualificationTypeArray = qualificationType.map((qualType, idx) => {
    return {
      name: qualType.name,
      value: qualType.short_code,
      id: qualType.id ? qualType.id : idx,
    };
  });

  const [highestQualificationArray, setHighestQualificationArray] = useState(
    []
  );

  // console.log(qualificationTypeArray);

  useEffect(() => {
    if (touched.religion) {
      if (!formData.religion) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          religion: 'Please select religion!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          religion: '',
        }));
      }
    }
  }, [formData.religion, touched.religion]);

  useEffect(() => {
    if (touched.community) {
      if (!formData.community) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          community: 'Please select community!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          community: '',
        }));
      }
    }
  }, [formData.community, touched.community]);

  useEffect(() => {
    if (touched.sub_community) {
      if (!formData.sub_community) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          sub_community: 'Please select sub community!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          sub_community: '',
        }));
      }
    }
  }, [formData.sub_community, touched.sub_community]);

  useEffect(() => {
    if (touched.mother_tongue) {
      if (!formData.mother_tongue) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          mother_tongue: 'Please select mother tongue!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          mother_tongue: '',
        }));
      }
    }
  }, [formData.mother_tongue, touched.mother_tongue]);

  useEffect(() => {
    if (touched.highest_qualification) {
      if (!formData.highest_qualification) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          highest_qualification: 'Please select highest qualification!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          highest_qualification: '',
        }));
      }
    }
  }, [formData.highest_qualification, touched.highest_qualification]);

  useEffect(() => {
    if (touched.qualification_type) {
      // console.log('touched');
      setFormData((prevData) => ({ ...prevData, highest_qualification: '' }));

      if (!formData.qualification_type) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          qualification_type: 'Please select qualification type',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          qualification_type: '',
        }));
      }
    }
  }, [formData.qualification_type, touched.qualification_type]);

  useEffect(() => {
    if (touched.occupation_type) {
      if (!formData.occupation_type) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          occupation_type: 'Please select occupation ',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          occupation_type: '',
        }));
      }
    }
  }, [formData.occupation_type, touched.occupation_type]);

  useEffect(() => {
    if (touched.current_income) {
      if (!formData.current_income) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          current_income: 'Please select annual income',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          current_income: '',
        }));
      }
    }
  }, [formData.current_income, touched.current_income]);

  useEffect(() => {
    if (touched.occupation_detail) {
      if (!formData.occupation_detail) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          occupation_detail: 'Please enter occupation detail',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          occupation_detail: '',
        }));
      }
    }
  }, [formData.occupation_detail, touched.occupation_detail]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    let updatedFormData = { ...formData, [id]: value };

    // console.log('value: ', value);
    if (id === 'qualification_type') {
      const selectedHighestQualification = highestQualification.filter(
        (hq) => hq.reference_short_code === value
      );

      selectedHighestQualification &&
        setHighestQualificationArray(selectedHighestQualification);
    }

    // console.log(updatedFormData);
    setFormData((prevData) => ({ ...prevData, ...updatedFormData }));
    // console.log(formData);
    setTouched((prevTouched) => ({
      ...prevTouched,
      [id]: true,
    }));
  };

  const validateInputs = () => {
    let valid = true;
    const errors = {
      religion: '',
      community: '',
      sub_community: '',
      mother_tongue: '',
      qualification_type: '',
      highest_qualification: '',
      occupation_type: '',
      occupation_detail: '',
      current_income: '',
    };

    if (formData.religion === '') {
      errors.religion = 'Please select religion!';
      valid = false;
    }
    if (formData.community === '') {
      errors.community = 'Please select community!';
      valid = false;
    }
    if (formData.sub_community === '') {
      errors.sub_community = 'Please select sub community!';
      valid = false;
    }
    if (formData.mother_tongue === '') {
      errors.mother_tongue = 'Please select mother tongue!';
      valid = false;
    }
    if (!formData.qualification_type) {
      errors.qualification_type = 'Please select qualification type!';
      valid = false;
    }
    if (
      formData.highest_qualification === '' ||
      formData.highest_qualification === 'Select'
    ) {
      errors.highest_qualification = 'Please select highest qualification!';
      valid = false;
    }
    if (formData.occupation_type === '') {
      errors.occupation_type = 'Please select occupation!';
      valid = false;
    }
    if (formData.occupation_detail === '') {
      errors.occupation_detail = 'Please enter occupation detail!';
      valid = false;
    }
    if (formData.current_income === '') {
      errors.current_income = 'Please enter annual income!';
      valid = false;
    }

    setFormErrors(errors);
    return valid;
  };

  const handleOnNext = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError(null);

    if (!validateInputs()) {
      setIsLoading(false);
      return;
    }

    try {
      console.log(formData);
      // console.log('occupation_type', formData.occupation_type);
      formData.occupation_type = parseInt(formData.occupation_type);
      formData.religion = parseInt(formData.religion);
      formData.community = parseInt(formData.community);
      formData.highest_qualification = parseInt(formData.highest_qualification);
      formData.sub_community = parseInt(formData.sub_community);
      formData.mother_tongue = parseInt(formData.mother_tongue);
      // Post data to API
      onNext(formData);
    } catch (err) {
      setError(err.message);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleOnNext}
      className='flex flex-col justify-start items-start w-full lg:w-[100%] mt-4 h-[400px] md:h-auto'
    >
      <div className='flex flex-col md:flex-row justify-between items-start w-full'>
        <div className='flex justify-center items-center w-full md:w-[48%]'>
          <SelectInput
            id='religion'
            name='religion'
            selectType='id'
            label='Religion:'
            options={religionArray}
            className='w-full'
            value={formData.religion}
            onChange={handleChange}
            error={formErrors.religion}
          />
        </div>
        <div className='flex justify-center items-end w-full md:w-[48%]'>
          <SelectInput
            id='community'
            name='community'
            selectType='id'
            label='Community:'
            options={communityArray}
            className='w-full'
            value={formData.community}
            onChange={handleChange}
            error={formErrors.community}
          />
        </div>
      </div>
      <div className='flex flex-col md:flex-row justify-between items-start w-full'>
        <div className='flex justify-center items-center w-full md:w-[48%]'>
          <SelectInput
            id='sub_community'
            name='sub_community'
            selectType='id'
            label='Sub-Community:'
            options={subCommunityArray}
            className='w-full'
            value={formData.sub_community}
            onChange={handleChange}
            error={formErrors.sub_community}
          />
        </div>
        <div className='flex justify-center items-center w-full md:w-[48%]'>
          <SelectInput
            id='mother_tongue'
            name='mother_tongue'
            selectType='id'
            label='Mother-Tongue:'
            options={motherTongueArray}
            className='w-full'
            value={formData.mother_tongue}
            onChange={handleChange}
            error={formErrors.mother_tongue}
          />
        </div>
      </div>
      <div className='flex flex-col md:flex-row justify-center md:justify-between items-start w-full'>
        <div className='flex justify-center items-center w-full md:w-[49%]'>
          <SelectInput
            id='qualification_type'
            label='Qualification-Type:'
            selectType='value'
            options={qualificationTypeArray}
            value={formData.qualification_type}
            onChange={handleChange}
            className='font-semibold cursor-pointer'
            width='w-full'
            error={formErrors.qualification_type}
          />
        </div>
        <div className='flex justify-center items-center w-full md:w-[49%]'>
          <SelectInput
            id='highest_qualification'
            label='Highest Qualification:'
            selectType='id'
            options={highestQualificationArray}
            value={formData.highest_qualification}
            onChange={handleChange}
            className='font-semibold cursor-pointer'
            width='w-full'
            error={formErrors.highest_qualification}
          />
        </div>
      </div>
      <div className='flex flex-col md:flex-row justify-center md:justify-between items-start w-full'>
        <div className='flex justify-center items-center w-full md:w-[49%]'>
          <SelectInput
            id='occupation_type'
            label='Occupation:'
            options={occupationArray}
            value={formData.occupation_type}
            selectType='id'
            onChange={handleChange}
            className='font-semibold cursor-pointer'
            width='w-full'
            error={formErrors.occupation_type}
          />
        </div>
        {/* {console.log(occupationArray)} */}
        <div className='flex justify-center items-center w-full md:w-[49%]'>
          <Input
            id='occupation_detail'
            placeholder='Enter occupation detail'
            label='Occupation Detail:'
            className='w-full'
            value={formData.occupation_detail}
            onChange={handleChange}
            error={formErrors.occupation_detail}
          />
        </div>
      </div>
      <div className='flex flex-col md:flex-row justify-center md:justify-between items-start w-full'>
        <SelectInput
          id='current_income'
          label='Current Income:'
          selectType='value'
          options={annualIncomeArray}
          value={formData.current_income}
          onChange={handleChange}
          className='font-semibold cursor-pointer'
          width='w-full'
          error={formErrors.current_income}
        />
      </div>

      <div className='flex flex-col justify-center items-start w-full mt-10 mb-2'>
        <div className='flex justify-between items-center w-full'>
          <Button
            text='Prev'
            onButtonClick={onPrev}
            className='w-[48%] h-[50px] font-bold text-[24px]'
            bgBtn='bg-[#333333] hover:bg-[#222222 ]'
          />
          <Button
            text={isLoading ? 'Loading...' : 'Next'}
            className='w-[48%] h-[50px] font-bold text-[24px]'
          >
            {isLoading && <AiOutlineLoading className='animate-spin ml-2' />}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SignUpStep3;
