import Button from '../../../components/UI/Button/Button';
import Navbar from '../../../components/Navbar/Navbar';
import { useDispatch, useSelector } from 'react-redux';
import UserProfilePictureData from '../UserProfile/UserProfilePictureData';
import UserAboutData from '../UserProfile/UserAboutData';
import UserContactData from '../UserProfile/UserContactData';
import UserGalleryData from '../UserProfile/UserGalleryData';
import UserFamilyData from '../UserProfile/UserFamilyData';
import UserPersonalData from '../UserProfile/UserPersonalData';
import AboutFamily from '../UserProfile/AboutFamily';
import UserEducationData from '../UserProfile/UserEducationData';
import UserHobbiesData from '../UserProfile/UserHobbiesData';
import { useParams } from 'react-router-dom';
// import { getUserFullDetails } from '../../../redux/features/userSlice';
import { useEffect, useState } from 'react';
import {
  fetchPhotoAlbumByProfileId,
  fetchProfilePictureByProfileId,
  fetchProfilePicUrlMatchDetailByProfileId,
} from '../../../redux/features/imageSlice';

const ViewProfile = (props) => {
  const dispatch = useDispatch();
  const [profilePicture, setProfilePicture] = useState(null);
  const [photoAlbum, setPhotoAlbum] = useState(null);

  const { profile_id } = useParams();
  // console.log('profile_id: ', profile_id);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       await dispatch(getUserFullDetails(profile_id));
  //     } catch (error) {
  //       console.error('Error fetching data', error);
  //     }
  //   };
  //   fetchData();
  // }, [dispatch]);

  useEffect(() => {
    if (profile_id) {
      const fetchPicture = async () => {
        const picResponse = await dispatch(
          fetchProfilePictureByProfileId(profile_id)
        );
        const albumResponse = await dispatch(
          fetchPhotoAlbumByProfileId(profile_id)
        );
        await dispatch(fetchProfilePicUrlMatchDetailByProfileId(profile_id));
        // console.log(picResponse?.payload);
        if (picResponse?.payload) {
          setProfilePicture(picResponse.payload.profile_picture);
        }
        if (albumResponse?.payload) {
          setPhotoAlbum(albumResponse?.payload?.photo_album);
        }
      };
      fetchPicture();
    }
  }, [dispatch, profile_id]);

  const userFullDetail = useSelector((state) => state.user.userFullDetail);
  // userFullDetail && console.log('userFullDetailByID: ', userFullDetail);

  // const user = useSelector((state) => state.user.userInfo);
  // const { name, email } = user || {} ;

  // const { email } = user || {};
  const {
    occupation_detail,
    height_ft,
    height_inch,
    dob,
    city,
    first_name,
    last_name,
    about_myself,

    mobile,
    address,

    father_name,
    mother_name,
    father_status_occupation,
    mother_status_occupation,
    number_of_siblings,
    family_type,
    family_city,
    family_values,
    family_country,
    family_state,
    family_pincode,
    siblings_brother,
    siblings_sister,
    siblings_brother_married,
    siblings_sister_married,

    sub_community,
    weight,
    weight_measure,
    blood_group,
    marital_status,
    mother_tongue,
    religion,
    community,
    gothra,
    physically_status,
    complexion,
    time_of_birth,
    place_of_birth,
    manglic_status,
    raashi,

    about_my_family,

    highest_qualification,
    institute_name,
    current_income,
    occupation_type,

    hobbies,
  } = userFullDetail || {};
  // } = user?.additional_details || {};

  return (
    <>
      <Navbar onShowSignUp={props.onShowSignUp} />
      <section className='flex justify-center items-center w-full bg-[#FFFFF4] pb-32 pt-6 md:pt-6 lg:pt-10 mb-6 px-2 md:px-6 lg:px-10 mt-20'>
        <div className='flex flex-col justify-center items-center w-full p-4 border-2 border-[#FFC0CB] rounded-lg'>
          <div className='flex flex-col justify-center items-center w-full mb-10 bg-white'>
            <p className='font-bold text-[#FF4061] text-[38px] lg:text-[52px] my-2 text-center'>
              Profile
            </p>
            <p className='font-semibold text-[#818181] text-[16px] md:text-[18px] lg:text-[24px] my-2 text-center'>
              Most Trusted and premium Matrimony Service in the World.
            </p>
          </div>
          <div className='flex flex-col justify-center items-center w-full bg-white'>
            <div className='flex flex-col lg:flex-row justify-between items-start w-[98%]'>
              <UserProfilePictureData
                profile_id={profile_id}
                profilePicture={profilePicture}
              />
              <UserAboutData
                occupation_detail={occupation_detail}
                height_ft={height_ft}
                height_inch={height_inch}
                dob={dob}
                city={city}
                first_name={first_name}
                last_name={last_name}
                about_myself={about_myself}
                profile_id={profile_id}
              />
            </div>
            <div className='flex flex-col md:flex-row justify-between items-start w-[98%] border-b-'>
              <UserContactData email={null} mobile={mobile} address={address} />
              <UserGalleryData photoAlbumData={photoAlbum} />
            </div>
            <div className='flex flex-col justify-center items-center w-[98%] border-b-'>
              <div className='flex flex-col md:flex-row justify-between items-start w-full'>
                <UserFamilyData
                  father_name={father_name}
                  mother_name={mother_name}
                  father_status_occupation={father_status_occupation}
                  mother_status_occupation={mother_status_occupation}
                  number_of_siblings={number_of_siblings}
                  family_type={family_type}
                  family_city={family_city}
                  family_values={family_values}
                  family_country={family_country}
                  family_state={family_state}
                  family_pincode={family_pincode}
                  siblings_brother={siblings_brother}
                  siblings_sister={siblings_sister}
                  siblings_brother_married={siblings_brother_married}
                  siblings_sister_married={siblings_sister_married}
                />
                <UserPersonalData
                  first_name={first_name}
                  last_name={last_name}
                  dob={dob}
                  sub_community={sub_community}
                  height_ft={height_ft}
                  height_inch={height_inch}
                  weight={weight}
                  weight_measure={weight_measure}
                  blood_group={blood_group}
                  marital_status={marital_status}
                  mother_tongue={mother_tongue}
                  religion={religion}
                  community={community}
                  gothra={gothra}
                  physically_status={physically_status}
                  complexion={complexion}
                  time_of_birth={time_of_birth}
                  place_of_birth={place_of_birth}
                  manglic_status={manglic_status}
                  raashi={raashi}
                />
              </div>
              <div className='flex justify-center items-center w-full pb-10'>
                <AboutFamily aboutText={about_my_family || ''} />
              </div>
            </div>
            {/* <div className='flex flex-col justify-center items-center w-[98%] border-b-2'>
              <div className='flex flex-col md:flex-row justify-between items-start w-full'>
                <UserFamilyData />
                <UserPersonalData />
              </div>
              <div className='flex justify-center items-center w-full pb-10'>
                <AboutFamily aboutText={aboutText} />
              </div>
            </div> */}

            <div className='flex flex-col lg:flex-row justify-between items-start w-[98%]'>
              <UserEducationData
                highest_qualification={highest_qualification}
                institute_name={institute_name}
                current_income={current_income}
                occupation_type={occupation_type}
                occupation_detail={occupation_detail}
              />
              <UserHobbiesData hobbies={hobbies} />
            </div>
            <div className='flex justify-center items-center w-[98%]'>
              <Button
                text='Match Now'
                className='w-[80%] md:w-[50%] !h-[55px] font-bold'
                bgBtn='bg-[#333333]'
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewProfile;
