import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home/Home';
import AllMatches from './pages/AllMatches/AllMatches';
import DashboardPage from './pages/UserDashboard/UserDashboardPages/DashboardPage/DashboardPage';
import Membership from './pages/UserDashboard/UserDashboardPages/MembershipPages/Membership';
import PersonalProfile from './pages/UserDashboard/UserDashboardPages/EditProfile/PersonalProfile';
import ReligiousProfile from './pages/UserDashboard/UserDashboardPages/EditProfile/ReligiousProfile';
import FamilyProfile from './pages/UserDashboard/UserDashboardPages/EditProfile/FamilyProfile';
import EducationProfile from './pages/UserDashboard/UserDashboardPages/EditProfile/EducationProfile';
import AddressProfile from './pages/UserDashboard/UserDashboardPages/EditProfile/AddressProfile';
import PhotoAlbum from './pages/UserDashboard/UserDashboardPages/PhotoAlbumPages/PhotoAlbum';
import LatestRequest from './pages/UserDashboard/UserDashboardPages/DashboardPage/LatestRequest/LatestRequest';
import AboutMore from './pages/UserDashboard/UserDashboardPages/EditProfile/AboutMore';
import MatchPreferences from './pages/UserDashboard/UserDashboardPages/EditProfile/MatchPreferences';
import FindProfile from './pages/UserDashboard/UserDashboardPages/FindProfilePages/FindProfile';
import ProfileLooksLike from './pages/UserDashboard/UserDashboardPages/ProfileLooksLikePages/ProfileLooksLike';
import HoroscopeEdit from './pages/UserDashboard/UserDashboardPages/EditProfile/HoroscopeEdit';
import PrivateRoute from './components/PrivateRoute';
import AboutUs from './pages/aboutUs/AboutUs';
import TermsAndCondition from './pages/termsAndCondition/TermsAndCondition';
import RefundPolicy from './pages/refundPolicy/RefundPolicy';
// import SearchProfile from './pages/UserDashboard/UserDashboardPages/FindProfilePages/SearchProfile';
import PrivacyPolicy from './pages/privacyPolicy/PrivacyPolicy';
import CancellationPolicy from './pages/cancellationPolicy/CancellationPolicy';
import ViewProfile from './pages/AllMatches/viewProfile/ViewProfile';
import MatchNow from './pages/MatchNow/MatchNow';

const AppRoutes = ({
  onShowBasicInfo,
  onShowSignUp,
  onShowPaymentLayout,
  onShowSuccessMessage,
}) => {
  return (
    <Routes>
      {/* Public Routes */}

      <Route
        path='/'
        element={
          <Home onShowBasicInfo={onShowBasicInfo} onShowSignUp={onShowSignUp} />
        }
      />

      <Route path='/about-us' element={<AboutUs />} />
      <Route path='/terms-condition' element={<TermsAndCondition />} />
      <Route path='/refund-policy' element={<RefundPolicy />} />
      <Route path='/privacy-policy' element={<PrivacyPolicy />} />
      <Route path='/privacy-policy' element={<PrivacyPolicy />} />
      <Route path='/cancellation-policy' element={<CancellationPolicy />} />

      {/* Protected Routes */}
      <Route element={<PrivateRoute />}>
        <Route
          path='/all-matches'
          element={
            <AllMatches
              onShowBasicInfo={onShowBasicInfo}
              onShowSignUp={onShowSignUp}
            />
          }
        />

        <Route
          path='/dashboard'
          element={
            <DashboardPage
              onShowSignUp={onShowSignUp}
              onShowPaymentLayout={onShowPaymentLayout}
            />
          }
        />
        <Route path='/dashboard/latest-request' element={<LatestRequest />} />
        <Route
          path='/membership'
          element={<Membership onShowSignUp={onShowSignUp} />}
        />
        {/* <Route
          path='/searchprofile'
          element={<SearchProfile onShowSignUp={onShowSignUp} />}
        /> */}
        <Route
          path='/findprofile'
          element={<FindProfile onShowSignUp={onShowSignUp} />}
        />

        <Route
          path='/edit-profile'
          element={<PersonalProfile onShowEditSuccess={onShowSuccessMessage} />}
        />
        <Route
          path='/edit-profile/personal'
          element={
            <PersonalProfile
              onShowSignUp={onShowSignUp}
              onShowEditSuccess={onShowSuccessMessage}
            />
          }
        />
        <Route
          path='/edit-profile/religious'
          element={
            <ReligiousProfile
              onShowSignUp={onShowSignUp}
              onShowEditSuccess={onShowSuccessMessage}
            />
          }
        />
        <Route
          path='/edit-profile/family'
          element={
            <FamilyProfile
              onShowSignUp={onShowSignUp}
              onShowEditSuccess={onShowSuccessMessage}
            />
          }
        />
        <Route
          path='/edit-profile/education'
          element={
            <EducationProfile
              onShowSignUp={onShowSignUp}
              onShowEditSuccess={onShowSuccessMessage}
            />
          }
        />
        <Route
          path='/edit-profile/address'
          element={
            <AddressProfile
              onShowSignUp={onShowSignUp}
              onShowEditSuccess={onShowSuccessMessage}
            />
          }
        />

        <Route
          path='/edit-profile/horoscopeedit'
          element={
            <HoroscopeEdit
              onShowSignUp={onShowSignUp}
              onShowEditSuccess={onShowSuccessMessage}
            />
          }
        />
        <Route
          path='/edit-profile/aboutmore'
          element={
            <AboutMore
              onShowSignUp={onShowSignUp}
              onShowEditSuccess={onShowSuccessMessage}
            />
          }
        />
        <Route
          path='/edit-profile/matchpreferences'
          element={
            <MatchPreferences
              onShowSignUp={onShowSignUp}
              onShowEditSuccess={onShowSuccessMessage}
            />
          }
        />
        <Route
          path='/photo-album'
          element={<PhotoAlbum onShowSignUp={onShowSignUp} />}
        />

        <Route
          path='/profilelookslike'
          onShowSignUp={onShowSignUp}
          element={<ProfileLooksLike />}
        />
        <Route path='/view-profile/:profile_id' element={<ViewProfile />} />
        {/* <Route path='/match-now' element={<MatchNow />} /> */}
        <Route path='/view-profile/match-now' element={<MatchNow />} />
      </Route>

      {/* Catch-all redirect to home for unauthenticated users */}
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
};

export default AppRoutes;

// import React from 'react';
// import { Route, Routes } from 'react-router-dom';
// import Home from './pages/Home/Home';
// import AllMatches from './pages/AllMatches/AllMatches';
// import DashboardPage from './pages/UserDashboard/UserDashboardPages/DashboardPage/DashboardPage';
// import Membership from './pages/UserDashboard/UserDashboardPages/MembershipPages/Membership';
// import PersonalProfile from './pages/UserDashboard/UserDashboardPages/EditProfile/PersonalProfile';
// import ReligiousProfile from './pages/UserDashboard/UserDashboardPages/EditProfile/ReligiousProfile';
// import FamilyProfile from './pages/UserDashboard/UserDashboardPages/EditProfile/FamilyProfile';
// import EducationProfile from './pages/UserDashboard/UserDashboardPages/EditProfile/EducationProfile';
// import AddressProfile from './pages/UserDashboard/UserDashboardPages/EditProfile/AddressProfile';
// import LifestyleSocial from './pages/UserDashboard/UserDashboardPages/EditProfile/LifestyleSocial';
// import PhotoAlbum from './pages/UserDashboard/UserDashboardPages/PhotoAlbumPages/PhotoAlbum';
// import Message from './pages/UserDashboard/UserDashboardPages/MessagePages/Message';
// import Horoscope from './pages/UserDashboard/UserDashboardPages/HoroscopePage/Horoscope';
// // import ProfileLooksLike from "./pages/UserDashboard/UserDashboardPages/ProfileLooksLikePages/ProfileLooksLike";
// import UserProfile from './pages/AllMatches/UserProfile/UserProfile';
// import LatestRequest from './pages/UserDashboard/UserDashboardPages/DashboardPage/LatestRequest/LatestRequest';
// import AboutMore from './pages/UserDashboard/UserDashboardPages/EditProfile/AboutMore';
// import MatchPreferences from './pages/UserDashboard/UserDashboardPages/EditProfile/MatchPreferences';
// import SearchProfile from './pages/UserDashboard/UserDashboardPages/FindProfilePages/SearchProfile';
// import FindProfile from './pages/UserDashboard/UserDashboardPages/FindProfilePages/FindProfile';
// import HoroscopeEdit from './pages/UserDashboard/UserDashboardPages/EditProfile/HoroscopeEdit';

// const AppRoutes = ({
//   onShowBasicInfo,
//   onShowSignUp,
//   onShowPaymentLayout,
//   onShowSuccessMessage,
// }) => {
//   return (
//     <Routes>
//       <Route
//         path='/'
//         element={
//           <Home onShowBasicInfo={onShowBasicInfo} onShowSignUp={onShowSignUp} />
//         }
//       />

//       <Route
//         path='/all-matches'
//         element={
//           <AllMatches
//             onShowBasicInfo={onShowBasicInfo}
//             onShowSignUp={onShowSignUp}
//           />
//         }
//       />
//       <Route path='/view-profile' element={<UserProfile />} />

//       <Route
//         path='/dashboard'
//         element={
//           <DashboardPage
//             onShowSignUp={onShowSignUp}
//             onShowPaymentLayout={onShowPaymentLayout}
//           />
//         }
//       />
//       <Route path='/dashboard/latest-request' element={<LatestRequest />} />

//       <Route
//         path='/searchprofile'
//         element={<SearchProfile onShowSignUp={onShowSignUp} />}
//       />
//       <Route
//         path='/findprofile'
//         element={<FindProfile onShowSignUp={onShowSignUp} />}
//       />

//       <Route
//         path='/membership'
//         element={<Membership onShowSignUp={onShowSignUp} />}
//       />

//       <Route
//         path='/edit-profile'
//         element={<PersonalProfile onShowEditSuccess={onShowSuccessMessage} />}
//       />
//       <Route
//         path='/edit-profile/personal'
//         element={
//           <PersonalProfile
//             onShowSignUp={onShowSignUp}
//             onShowEditSuccess={onShowSuccessMessage}
//           />
//         }
//       />
//       <Route
//         path='/edit-profile/religious'
//         element={
//           <ReligiousProfile
//             onShowSignUp={onShowSignUp}
//             onShowEditSuccess={onShowSuccessMessage}
//           />
//         }
//       />
//       <Route
//         path='/edit-profile/family'
//         element={
//           <FamilyProfile
//             onShowSignUp={onShowSignUp}
//             onShowEditSuccess={onShowSuccessMessage}
//           />
//         }
//       />
//       <Route
//         path='/edit-profile/education'
//         element={
//           <EducationProfile
//             onShowSignUp={onShowSignUp}
//             onShowEditSuccess={onShowSuccessMessage}
//           />
//         }
//       />
//       <Route
//         path='/edit-profile/address'
//         element={
//           <AddressProfile
//             onShowSignUp={onShowSignUp}
//             onShowEditSuccess={onShowSuccessMessage}
//           />
//         }
//       />
//       <Route
//         path='/edit-profile/lifestylesocial'
//         element={
//           <LifestyleSocial
//             onShowSignUp={onShowSignUp}
//             onShowEditSuccess={onShowSuccessMessage}
//           />
//         }
//       />
//       <Route
//         path='/edit-profile/horoscopeedit'
//         element={
//           <HoroscopeEdit
//             onShowSignUp={onShowSignUp}
//             onShowEditSuccess={onShowSuccessMessage}
//           />
//         }
//       />
//       <Route
//         path='/edit-profile/aboutmore'
//         element={
//           <AboutMore
//             onShowSignUp={onShowSignUp}
//             onShowEditSuccess={onShowSuccessMessage}
//           />
//         }
//       />
//       <Route
//         path='/edit-profile/matchpreferences'
//         element={
//           <MatchPreferences
//             onShowSignUp={onShowSignUp}
//             onShowEditSuccess={onShowSuccessMessage}
//           />
//         }
//       />

//       <Route
//         path='/photo-album'
//         element={<PhotoAlbum onShowSignUp={onShowSignUp} />}
//       />

//       <Route
//         path='/message'
//         element={<Message onShowSignUp={onShowSignUp} />}
//       />
//       <Route
//         path='/horoscope'
//         element={<Horoscope onShowSignUp={onShowSignUp} />}
//       />
//       <Route
//         path='/horoscope'
//         element={<Horoscope onShowSignUp={onShowSignUp} />}
//       />
//       <Route
//         path='/profile-looks-like'
//         element={<UserProfile onShowSignUp={onShowSignUp} />}
//       />
//       {/* <Route path="//profile-looks-like" element={<ProfileLooksLike />} /> */}
//     </Routes>
//   );
// };

// export default AppRoutes;
