import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import defaultProfileIcon from './images/defaultProfileIcon.png';

const MatchNow = () => {
  const navigate = useNavigate();

  const comparison = useSelector((state) => state.user.compareUserDetails);

  const profilePicUrlMyDetail = useSelector(
    (state) => state.image.profilePicUrlMyDetail
  );
  const profilePicUrlMatchDetail = useSelector(
    (state) => state.image.profilePicUrlMatchDetail
  );

  // console.log(profilePicUrlMyDetail);
  // console.log(profilePicUrlMatchDetail);

  const annualIncomeData = useSelector((state) => state.field.annualIncome);
  const cityData = useSelector((state) => state.field.city);
  const occupationData = useSelector((state) => state.field.occupation);
  const physicalStatusData = useSelector((state) => state.field.physicalStatus);
  const religionData = useSelector((state) => state.field.religion);

  if (!comparison) {
    navigate('/dashboard');
    return (
      <div className='max-w-4xl mx-auto min-h-[40vh] p-4 pt-20 mt-10 pb-20 mb-20'>
        Loading...
      </div>
    );
  }

  const noData = (
    <span className='text-[#ff0000] opacity-60'>not provided</span>
  );

  const getCityNameById = (id) =>
    cityData.find((city) => city.id === id * 1)?.name || 'Not provided';
  const getCurrentIncomeById = (income_value) =>
    annualIncomeData.find((income) => income.value === income_value)?.desc ||
    'Not provided';
  const getOccupationNameById = (id) =>
    occupationData.find((job) => job.id === id * 1)?.name || 'Not provided';
  const getPhysicalStatusById = (id) =>
    physicalStatusData.find((status) => status.id === id * 1)?.status ||
    'Not provided';
  const getReligionNameById = (id) =>
    religionData.find((religion) => religion.id === id * 1)?.name ||
    'Not provided';

  const dataRows = [
    {
      label: 'Profile Picture',
      myDetail: (
        <div className='flex justify-center items-center w-12 h-12 rounded-full overflow-hidden'>
          <img
            src={
              profilePicUrlMyDetail
                ? `https://api.dev.sanroj.com${profilePicUrlMyDetail}`
                : defaultProfileIcon
            }
            alt='My Profile'
            className='object-cover w-full h-full'
          />
        </div>
      ),
      matchDetail: (
        <div className='flex justify-center items-center w-12 h-12 rounded-full overflow-hidden'>
          <img
            src={
              profilePicUrlMatchDetail
                ? `https://api.dev.sanroj.com${profilePicUrlMatchDetail}`
                : defaultProfileIcon
            }
            alt='Match Profile'
            className='object-cover w-full h-full'
          />
        </div>
      ),
    },
    {
      label: 'Name',
      myDetail: comparison.names.my_detail || noData,
      matchDetail: comparison.names.target_user || noData,
    },
    {
      label: 'Gender',
      myDetail: comparison.gender.my_detail || noData,
      matchDetail: comparison.gender.target_user || noData,
    },
    {
      label: 'Age',
      myDetail: comparison.age.my_detail
        ? `${comparison.age.my_detail} years`
        : noData,
      matchDetail: comparison.age.target_user
        ? `${comparison.age.target_user} years`
        : noData,
    },
    {
      label: 'Complexion',
      myDetail: comparison.complexion.my_detail || noData,
      matchDetail: comparison.complexion.target_user || noData,
    },
    {
      label: 'Height',
      myDetail: comparison.height.my_detail || noData,
      matchDetail: comparison.height.target_user || noData,
    },
    {
      label: 'Hobbies',
      myDetail: comparison.hobbies.my_detail || noData,
      matchDetail: comparison.hobbies.target_user || noData,
    },
    {
      label: 'Income',
      myDetail: getCurrentIncomeById(comparison.income.my_detail) || noData,
      matchDetail:
        getCurrentIncomeById(comparison.income.target_user) || noData,
    },
    {
      label: 'Location',
      myDetail: getCityNameById(comparison.location.my_detail),
      matchDetail: getCityNameById(comparison.location.target_user),
    },
    {
      label: 'Marital Status',
      myDetail: comparison.marital_status.my_detail || noData,
      matchDetail: comparison.marital_status.target_user || noData,
    },
    {
      label: 'Occupation Detail',
      myDetail: comparison.occupation_detail.my_detail || noData,
      matchDetail: comparison.occupation_detail.target_user || noData,
    },
    {
      label: 'Occupation Type',
      myDetail:
        getOccupationNameById(comparison.occupation_type.my_detail) || noData,
      matchDetail:
        getOccupationNameById(comparison.occupation_type.target_user) || noData,
    },
    {
      label: 'Physically Status',
      myDetail: getPhysicalStatusById(comparison.physically_status.my_detail),
      matchDetail: getPhysicalStatusById(
        comparison.physically_status.target_user
      ),
    },
    {
      label: 'Religion',
      myDetail: getReligionNameById(comparison.religion.my_detail),
      matchDetail: getReligionNameById(comparison.religion.target_user),
    },
  ];

  const formatHobbies = (hobbiesString) => {
    try {
      const hobbiesArray = JSON.parse(hobbiesString);
      return hobbiesArray.map((hobby) => `- ${hobby}`).join('<br />');
    } catch {
      return hobbiesString;
    }
  };

  return (
    <div className='max-w-4xl mx-auto min-h-[40vh] p-4 pt-20 mt-10 pb-20 mb-20'>
      <div className='bg-white rounded-lg shadow-lg p-6'>
        <h2 className='text-2xl font-bold text-[#FF4061] mb-4'>
          Profile Comparison
        </h2>
        <table className='w-full border border-gray-300 overflow-hidden'>
          <thead>
            <tr className='bg-[#FFC0CB]'>
              <th className='text-left p-2 font-semibold text-gray-700'>
                Labels
              </th>
              <th className='text-left p-2 font-semibold text-gray-700'>
                My Details
              </th>
              <th className='text-left p-2 font-semibold text-gray-700'>
                Match User Details
              </th>
            </tr>
          </thead>
          <tbody>
            {dataRows.map((row, index) => (
              <tr key={index} className='border-t border-gray-300'>
                <td className='p-2 text-gray-600 font-medium'>{row.label}</td>
                <td className='p-2 text-gray-600'>
                  {row.label === 'Hobbies' && row.myDetail !== noData ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: formatHobbies(row.myDetail),
                      }}
                    />
                  ) : (
                    row.myDetail
                  )}
                </td>
                <td className='p-2 text-gray-600'>
                  {row.label === 'Hobbies' && row.matchDetail !== noData ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: formatHobbies(row.matchDetail),
                      }}
                    />
                  ) : (
                    row.matchDetail
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MatchNow;

// import React from 'react';
// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import defaultProfileIcon from './images/defaultProfileIcon.png';

// const MatchNow = () => {
//   const navigate = useNavigate();

//   const comparison = useSelector((state) => state.user.compareUserDetails);
//   const annualIncomeData = useSelector((state) => state.field.annualIncome);
//   const cityData = useSelector((state) => state.field.city);
//   const occupationData = useSelector((state) => state.field.occupation);
//   const physicalStatusData = useSelector((state) => state.field.physicalStatus);
//   const religionData = useSelector((state) => state.field.religion);

//   if (!comparison) {
//     navigate('/dashboard');
//     return (
//       <div className='max-w-4xl mx-auto min-h-[40vh] p-4 pt-20 mt-10 pb-20 mb-20'>
//         Loading...
//       </div>
//     );
//   }

//   const noData = (
//     <span className='text-[#ff0000] opacity-60'>not provided</span>
//   );

//   const getCityNameById = (id) =>
//     cityData.find((city) => city.id === id * 1)?.name || 'Not provided';
//   const getCurrentIncomeById = (income_value) =>
//     annualIncomeData.find((income) => income.value === income_value)?.desc ||
//     'Not provided';
//   const getOccupationNameById = (id) =>
//     occupationData.find((job) => job.id === id * 1)?.name || 'Not provided';
//   const getPhysicalStatusById = (id) =>
//     physicalStatusData.find((status) => status.id === id * 1)?.status ||
//     'Not provided';
//   const getReligionNameById = (id) =>
//     religionData.find((religion) => religion.id === id * 1)?.name ||
//     'Not provided';

//   const dataRows = [
//     {
//       label: 'Name',
//       myDetail: comparison.names.my_detail || noData,
//       matchDetail: comparison.names.target_user || noData,
//     },
//     {
//       label: 'Profile Picture',
//       myDetail: defaultProfileIcon,
//       matchDetail: defaultProfileIcon,
//       // myDetail: comparison.gender.my_detail || noData,
//       // matchDetail: comparison.gender.target_user || noData,
//     },
//     {
//       label: 'Gender',
//       myDetail: comparison.gender.my_detail || noData,
//       matchDetail: comparison.gender.target_user || noData,
//     },
//     {
//       label: 'Age',
//       myDetail: comparison.age.my_detail
//         ? `${comparison.age.my_detail} years`
//         : noData,
//       matchDetail: comparison.age.target_user
//         ? `${comparison.age.target_user} years`
//         : noData,
//     },
//     {
//       label: 'Complexion',
//       myDetail: comparison.complexion.my_detail || noData,
//       matchDetail: comparison.complexion.target_user || noData,
//     },
//     {
//       label: 'Height',
//       myDetail: comparison.height.my_detail || noData,
//       matchDetail: comparison.height.target_user || noData,
//     },
//     {
//       label: 'Hobbies',
//       myDetail: comparison.hobbies.my_detail || noData,
//       matchDetail: comparison.hobbies.target_user || noData,
//     },
//     {
//       label: 'Income',
//       myDetail: getCurrentIncomeById(comparison.income.my_detail) || noData,
//       matchDetail:
//         getCurrentIncomeById(comparison.income.target_user) || noData,
//     },
//     {
//       label: 'Location',
//       myDetail: getCityNameById(comparison.location.my_detail),
//       matchDetail: getCityNameById(comparison.location.target_user),
//     },
//     {
//       label: 'Marital Status',
//       myDetail: comparison.marital_status.my_detail || noData,
//       matchDetail: comparison.marital_status.target_user || noData,
//     },
//     {
//       label: 'Occupation Detail',
//       myDetail: comparison.occupation_detail.my_detail || noData,
//       matchDetail: comparison.occupation_detail.target_user || noData,
//     },
//     {
//       label: 'Occupation Type',
//       myDetail:
//         getOccupationNameById(comparison.occupation_type.my_detail) || noData,
//       matchDetail:
//         getOccupationNameById(comparison.occupation_type.target_user) || noData,
//     },
//     {
//       label: 'Physically Status',
//       myDetail: getPhysicalStatusById(comparison.physically_status.my_detail),
//       matchDetail: getPhysicalStatusById(
//         comparison.physically_status.target_user
//       ),
//     },
//     {
//       label: 'Religion',
//       myDetail: getReligionNameById(comparison.religion.my_detail),
//       matchDetail: getReligionNameById(comparison.religion.target_user),
//     },
//   ];

//   const formatHobbies = (hobbiesString) => {
//     try {
//       const hobbiesArray = JSON.parse(hobbiesString);
//       return hobbiesArray.map((hobby) => `- ${hobby}`).join('<br />');
//     } catch {
//       return hobbiesString;
//     }
//   };

//   return (
//     <div className='max-w-4xl mx-auto min-h-[40vh] p-4 pt-20 mt-10 pb-20 mb-20'>
//       <div className='bg-white rounded-lg shadow-lg p-6'>
//         <h2 className='text-2xl font-bold text-[#FF4061] mb-4'>
//           Profile Comparison
//         </h2>
//         <table className='w-full border border-gray-300 overflow-hidden'>
//           <thead>
//             <tr className='bg-[#FFC0CB]'>
//               <th className='text-left p-2 font-semibold text-gray-700'>
//                 Labels
//               </th>
//               <th className='text-left p-2 font-semibold text-gray-700'>
//                 My Details
//               </th>
//               <th className='text-left p-2 font-semibold text-gray-700'>
//                 Match User Details
//               </th>
//             </tr>
//           </thead>
//           <tbody>
//             {dataRows.map((row, index) => (
//               <tr key={index} className='border-t border-gray-300'>
//                 <td className='p-2 text-gray-600 font-medium'>{row.label}</td>
//                 <td className='p-2 text-gray-600'>
//                   {row.label === 'Hobbies' && row.myDetail !== noData ? (
//                     <span
//                       dangerouslySetInnerHTML={{
//                         __html: formatHobbies(row.myDetail),
//                       }}
//                     />
//                   ) : (
//                     row.myDetail
//                   )}
//                 </td>
//                 <td className='p-2 text-gray-600'>
//                   {row.label === 'Hobbies' && row.matchDetail !== noData ? (
//                     <span
//                       dangerouslySetInnerHTML={{
//                         __html: formatHobbies(row.matchDetail),
//                       }}
//                     />
//                   ) : (
//                     row.matchDetail
//                   )}
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default MatchNow;
