export const normalizeProfileData = (profileData) => {
  if (!profileData) return null;

  const defaultProfileImage = '/path/to/default/image.png'; // Replace with the actual path to your fallback image

  return {
    full_name: profileData.name || profileData.full_name,
    profile_image:
      profileData.profile_photo ||
      profileData.profile_image ||
      defaultProfileImage,
    age: profileData.age,
    occupation:
      profileData.occupation?.occupation_type || profileData.occupation,
    marital_status: profileData.marital_status,
    highest_qualification:
      profileData.education?.highest_qualification ||
      profileData.highest_qualification,
    profile_id: profileData.profile_id,
    city: profileData.location?.city || profileData.city,
    gender: profileData.gender,
  };
};
