// import { AiOutlineLoading } from 'react-icons/ai';
import Button from '../../../../components/UI/Button/Button';
import SelectInput from '../../../../components/UI/SelectInput/SelectInput';
import EditProfileLayout from './EditProfileLayout';
import { useEffect, useState } from 'react';
import Input from '../../../../components/UI/Input/Input';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserReligiousProfile } from '../../../../redux/features/userSlice';
import { FaCheckCircle } from 'react-icons/fa';

const ReligiousProfile = (props) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const additionalDetails = useSelector(
    (state) => state.user.additionalDetails
  );
  console.log('Religios: ', additionalDetails);

  const religion = additionalDetails.religion || '';
  const community = additionalDetails.community || '';
  const subCommunity = additionalDetails.sub_community || '';
  const motherTongue = additionalDetails.mother_tongue || '';
  const gothra = additionalDetails.gothra || '';
  // console.log(religion);
  // console.log(community);
  // console.log(subCommunity);
  // console.log(motherTongue);

  const religionData = useSelector((state) => state.field.religion);
  const communityData = useSelector((state) => state.field.community);
  const subCommunityData = useSelector((state) => state.field.subCommunity);
  const motherTongueData = useSelector((state) => state.field.motherTounge);

  const religionOptions = religionData.filter(
    (options) => options.id !== religion
  );
  const communityOptions = communityData.filter(
    (options) => options.id !== community
  );
  const subCommunityOptions = subCommunityData.filter(
    (options) => options.id !== subCommunity
  );
  const motherTongueOptions = motherTongueData.filter(
    (options) => options.id !== motherTongue
  );

  const selectedReligion =
    religionData && religionData.length > 0
      ? religionData.find((relObj) => relObj.id === religion)?.name || ''
      : '';

  const selectedCommunity =
    communityData && communityData.length > 0
      ? communityData.find((commuObj) => commuObj.id === community)?.name || ''
      : '';
  const selectedSubCommunity =
    subCommunityData && subCommunityData.length > 0
      ? subCommunityData.find((subComObj) => subComObj.id === subCommunity)
          ?.name || ''
      : '';
  const selectedMotherTongue =
    motherTongueData && motherTongueData.length > 0
      ? motherTongueData.find((mothTonObj) => mothTonObj.id === motherTongue)
          ?.name || ''
      : '';

  // console.log(selectedSubCommunity);
  // console.log(selectedCommunity);
  // console.log(selectedReligion);
  // console.log(selectedMotherTongue);

  const [formData, setFormData] = useState({
    religion: religion || '',
    motherTongue: motherTongue || '',
    community: community || '',
    subCommunity: subCommunity || '',
    gothra: gothra || '',
  });

  const [formErrors, setFormErrors] = useState({
    religion: '',
    motherTongue: '',
    community: '',
    subCommunity: '',
    gothra: '',
  });

  const [touched, setTouched] = useState({
    religion: '',
    motherTongue: false,
    community: false,
    subCommunity: false,
    gothra: false,
  });

  useEffect(() => {
    if (touched.religion) {
      if (!formData.religion) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          religion: 'Please select religion',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          religion: '',
        }));
      }
    }
  }, [formData.religion, touched.religion]);

  useEffect(() => {
    if (touched.motherTongue) {
      if (!formData.motherTongue) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          motherTongue: 'Please select mother tongue',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          motherTongue: '',
        }));
      }
    }
  }, [formData.motherTongue, touched.motherTongue]);

  useEffect(() => {
    if (touched.community) {
      if (!formData.community) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          community: 'Please select community',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          community: '',
        }));
      }
    }
  }, [formData.community, touched.community]);

  useEffect(() => {
    if (touched.subCommunity) {
      if (!formData.community) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          subCommunity: 'Please select community first!',
        }));
      } else if (!formData.subCommunity) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          subCommunity: 'Please select sub community',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          subCommunity: '',
        }));
      }
    }
  }, [formData.subCommunity, touched.subCommunity, formData.community]);

  useEffect(() => {
    if (touched.gothra) {
      if (/[^a-zA-Z\s]/.test(formData.gothra)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          gothra: 'Only alphabets are allowed!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          gothra: '',
        }));
      }
    }
  }, [formData.gothra, touched.gothra]);

  console.log('handle');

  const handleChange = (e) => {
    const { id, value } = e.target;
    let updatedFormData = { ...formData, [id]: value };

    setFormData(updatedFormData);
    setTouched((prevTouched) => ({
      ...prevTouched,
      [id]: true,
    }));
  };

  const validateInputs = () => {
    let valid = true;
    const errors = {};

    if (!formData.religion) {
      errors.religion = 'Religion is required.';
      valid = false;
    }
    if (!formData.motherTongue) {
      errors.motherTongue = 'Mother tongue is required.';
      valid = false;
    }
    if (!formData.community) {
      errors.community = 'Community is required.';
      valid = false;
    }
    if (!formData.subCommunity) {
      errors.subCommunity = 'Sub-community is required.';
      valid = false;
    }
    if (!formData.gothra) {
      errors.gothra = 'Place of birth is required.';
      valid = false;
    } else if (/\d/.test(formData.gothra)) {
      errors.gothra = 'Place of birth cannot contain numbers.';
      valid = false;
    }

    setFormErrors(errors);
    return valid;
  };

  const handleOnSave = async () => {
    setIsLoading(true);
    setError(null);

    if (!validateInputs()) {
      setIsLoading(false);
      return;
    }

    try {
      const formDataToSubmit = {
        religion: parseInt(formData.religion),
        community: parseInt(formData.community),
        sub_community: parseInt(formData.subCommunity),
        mother_tongue: parseInt(formData.motherTongue),
        gothra: formData.gothra,
      };
      console.log(formDataToSubmit);
      // Post data to API
      await dispatch(updateUserReligiousProfile(formDataToSubmit));
      setSaveSuccess(true);
    } catch (err) {
      setError(err.message);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <EditProfileLayout onShowSignUp={props.onShowSignUp} editPage='religious'>
      <div className='flex flex-col justify-center items-center w-full h-full'>
        <div className='flex justify-start items-center w-full font-bold text-[18px] text-[#FF4061]'>
          Religious Information
        </div>
        <div className='flex flex-col md:flex-row justify-center md:justify-between items-start w-full '>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <SelectInput
              id='religion'
              label='Religion:'
              options={religionOptions}
              value={formData.religion}
              select={selectedReligion}
              selectType='id'
              onChange={handleChange}
              className='font-semibold cursor-pointer'
              width='w-full'
              error={formErrors.religion}
            />
          </div>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <SelectInput
              id='motherTongue'
              label='Mother Tongue:'
              options={motherTongueOptions}
              value={formData.motherTongue}
              selectType='id'
              select={selectedMotherTongue}
              onChange={handleChange}
              className='font-semibold cursor-pointer'
              width='w-full'
              error={formErrors.motherTongue}
            />
          </div>
        </div>
        <div className='flex flex-col md:flex-row justify-center md:justify-between items-start w-full'>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <SelectInput
              id='community'
              label='Community:'
              options={communityOptions}
              value={formData.community}
              select={selectedCommunity}
              selectType='id'
              onChange={handleChange}
              className='font-semibold cursor-pointer'
              width='w-full'
              error={formErrors.community}
            />
          </div>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <SelectInput
              id='subCommunity'
              label='Sub-Community:'
              options={subCommunityOptions}
              value={formData.subCommunity}
              select={selectedSubCommunity}
              selectType='id'
              onChange={handleChange}
              className='font-semibold cursor-pointer'
              width='w-full'
              error={formErrors.subCommunity}
            />
          </div>
        </div>
        <div className='flex flex-col md:flex-row justify-center md:justify-between items-start w-full'>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <Input
              id='gothra'
              label='Gothra:'
              placeholder='Enter your gothra'
              value={formData.gothra}
              onChange={handleChange}
              className='font-semibold cursor-pointer'
              width='w-full'
              error={formErrors.gothra}
            />
          </div>
        </div>
        <div className='flex justify-start items-center my-5 w-full'>
          <Button
            onButtonClick={handleOnSave}
            text={isLoading ? 'Loading...' : 'Save'}
            className='font-bold w-[130px] h-[50px]'
            disabled={isLoading}
          >
            {isLoading && (
              <div className='flex justify-center items-center bg-white rounded-full ml-2'>
                <svg
                  className='animate-spin h-6 w-6 text-pink-500'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-25'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  ></circle>
                  <path
                    className='opacity-75'
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8v8H4z'
                  ></path>
                </svg>
              </div>
            )}
          </Button>
          {saveSuccess && (
            <span className='flex justify-center items-center font-semibold text-[#333]'>
              <FaCheckCircle className='mr-2 text-[20px] text-[#4CAF50] ml-2' />
              saved
            </span>
          )}
        </div>
      </div>
    </EditProfileLayout>
  );
};

export default ReligiousProfile;
