/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Button from '../../../../components/UI/Button/Button';
import Input from '../../../../components/UI/Input/Input';
import SelectInput from '../../../../components/UI/SelectInput/SelectInput';
import EditProfileLayout from './EditProfileLayout';
// import { AiOutlineLoading } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserAddressEditProfile } from '../../../../redux/features/userSlice';
import { FaCheckCircle } from 'react-icons/fa';

const AddressProfile = (props) => {
  const dispatch = useDispatch();

  const additionalDetails = useSelector(
    (state) => state.user.additionalDetails
  );
  const {
    address,
    city,
    state,
    country,
    pincode,
    family_address,
    family_city,
    family_state,
    family_country,
    family_pincode,
  } = additionalDetails || {};
  // console.log(user);

  // console.log(
  //   address,
  //   city,
  //   state,
  //   country,
  //   pincode,
  //   family_address,
  //   family_city,
  //   family_state,
  //   family_country,
  //   family_pincode
  // );

  const [statesArrayPresent, setStatesArrayPresent] = useState([]);
  const [cityArrayPresent, setCityArrayPresent] = useState([]);

  const [statesArrayFamily, setStatesArrayFamily] = useState([]);
  const [cityArrayFamily, setCityArrayFamily] = useState([]);

  const [selectedStatePresent, setSelectedStatePresent] = useState([]);
  const [selectedCityPresent, setSelectedCityPresent] = useState([]);

  const [selectedStateFamily, setSelectedStateFamily] = useState([]);
  const [selectedCityFamily, setSelectedCityFamily] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [presentAddress, setPresentAddress] = useState({
    address: address || '',
    country: country || '',
    state: state || '',
    city: city || '',
    pincode: pincode || '',
  });
  const [familyAddress, setFamilyAddress] = useState({
    address: family_address || '',
    country: family_country || '',
    state: family_state || '',
    city: family_city || '',
    pincode: family_pincode || '',
  });
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [sameAsAbove, setSameAsAbove] = useState(false);
  const [touched, setTouched] = useState({
    present: {},
    family: {},
  });

  // console.log('PPPPPPPPPPPPPP', presentAddress);
  // console.log('FFFFFFFFFFFFFF', familyAddress);

  ///////////////DATA////////////////
  const countryData = useSelector((state) => state.field.country);
  const statesData = useSelector((state) => state.field.states);
  const cityData = useSelector((state) => state.field.city);
  // console.log(cityData);

  ////////////OPTIONS///////////
  const countryOptionsPresent = countryData.filter(
    (option) => option.id !== country
  );
  const statesOptionsPresent = statesData.filter(
    (option) => option.stcode !== presentAddress.state?.toLowerCase()
  );
  useEffect(() => {
    setStatesArrayPresent(statesOptionsPresent);
  }, [presentAddress.state]);

  const cityOptionsPresent = cityData.filter(
    (option) =>
      option.stcode === presentAddress.state &&
      option.id !== presentAddress.city
  );
  useEffect(() => {
    setCityArrayPresent(cityOptionsPresent);
  }, [presentAddress.state, presentAddress.city]);

  //////////Family/////////

  const countryOptionsFamily = countryData.filter(
    (option) => option.id !== country
  );
  const statesOptionsFam = statesData.filter(
    (option) => option.stcode !== familyAddress.state?.toLowerCase()
  );
  useEffect(() => {
    setStatesArrayFamily(statesOptionsFam);
  }, [familyAddress.state]);

  const cityOptionsFamily = cityData.filter(
    (option) =>
      option.stcode === familyAddress.state && option.id !== familyAddress.city
  );
  useEffect(() => {
    setCityArrayFamily(cityOptionsFamily);
  }, [familyAddress.state, familyAddress.city]);

  // console.log('countryOptionsPresent: ', countryOptionsPresent);
  // console.log('statesOptionsPresent: ', statesOptionsPresent);
  // console.log('cityOptionsPresent: ', cityOptionsPresent);

  ////////////SELECTED FIELDS//////////////
  const selectedCityPre =
    cityData && cityData.length > 0
      ? cityData.find((cityObj) => cityObj.id === presentAddress.city)?.name ||
        ''
      : '';
  useEffect(() => {
    setSelectedCityPresent(selectedCityPre);
  }, [presentAddress.city]);

  const selectedStatePre =
    statesData && statesData.length > 0
      ? statesData.find(
          (stateObj) =>
            stateObj.stcode.toLowerCase() ===
            presentAddress.state?.toLowerCase()
        )?.name || ''
      : '';
  useEffect(() => {
    setSelectedStatePresent(selectedStatePre);
  }, [presentAddress.state]);

  const selectedStateFam =
    statesData && statesData.length > 0
      ? statesData.find(
          (stateObj) =>
            stateObj.stcode.toLowerCase() === familyAddress.state?.toLowerCase()
        )?.name || ''
      : '';
  useEffect(() => {
    setSelectedStateFamily(selectedStateFam);
  }, [familyAddress.country, familyAddress.state]);

  // const selectedCityFam =
  //   cityData && cityData.length > 0
  //     ? cityData.find((cityObj) => cityObj.id === familyAddress.city)?.name ||
  //       ''
  //     : '';
  const selectedCityFam =
    cityData && cityData.length > 0
      ? cityData.find((cityObj) => cityObj.id === familyAddress.city * 1)
          ?.name || ''
      : '';

  // console.log('familyAddress.city: ', familyAddress.city);
  // console.log('cityData: ', cityData);
  // console.log('selectedCityFam: ', selectedCityFam);

  useEffect(() => {
    setSelectedCityFamily(selectedCityFam);
  }, [presentAddress.city]);
  // console.log('selectedCityFamily: ', selectedCityFamily);

  ////////////SELECTED FIELDS END//////////////

  let selectedCountryPre =
    countryData && countryData.length > 0
      ? countryData.find((countryObj) => countryObj.id === country)?.name || ''
      : '';
  let selectedCountryFam =
    countryData && countryData.length > 0
      ? countryData.find((countryObj) => countryObj.id === family_country * 1)
          ?.name || ''
      : '';
  // console.log('selectedState: ', selectedState);

  useEffect(() => {
    if (sameAsAbove) {
      setFamilyAddress(presentAddress);
    } else {
      // setFamilyAddress({
      //   address: '',
      //   country: '',
      //   state: '',
      //   city: '',
      //   pincode: '',
      // });
    }
  }, [presentAddress, sameAsAbove]);

  useEffect(() => {
    setPresentAddress((prevData) => ({
      ...prevData,
      country: country,
      state: state || '',
      city: city || '',
      pincode: pincode || '',
    }));
  }, [country, state, city, pincode]);

  useEffect(() => {
    setFamilyAddress((prevData) => ({
      ...prevData,
      address: family_address || '',
      country: family_country,
      state: family_state || '',
      city: family_city || '',
      pincode: family_pincode || '',
    }));
  }, [family_country, family_state, family_city, family_pincode]);

  // If 'Same as Above' is checked, update family address whenever present address changes

  // let countryArray = countryData.map((country, idx) => {
  //   return { name: country.name, id: country.id };
  // });

  const validateField = (field, value) => {
    let error = '';
    switch (field) {
      case 'address':
        if (!value) {
          error = 'Address is required.';
        }
        break;
      case 'city':
        if (!value) {
          error = 'City is required.';
        }
        break;
      case 'state':
        if (!value) {
          error = 'State is required.';
        }
        break;
      case 'country':
        if (!value) {
          error = 'Country is required.';
        }
        break;
      case 'pincode':
        if (!value) {
          error = 'Pincode is required.';
        } else if (!/^\d{6}$/.test(value)) {
          error = 'Pincode should be a 6-digit number.';
        }
        break;
      default:
        break;
    }
    return error;
  };

  useEffect(() => {
    const errors = { ...formErrors };
    Object.keys(presentAddress).forEach((field) => {
      if (touched.present[field]) {
        errors[`present${field.charAt(0).toUpperCase() + field.slice(1)}`] =
          validateField(field, presentAddress[field]);
      }
    });
    if (!sameAsAbove) {
      Object.keys(familyAddress).forEach((field) => {
        if (touched.family[field]) {
          errors[`family${field.charAt(0).toUpperCase() + field.slice(1)}`] =
            validateField(field, familyAddress[field]);
        }
      });
    }
    setFormErrors(errors);
  }, [presentAddress, familyAddress, touched, sameAsAbove]);

  const handleAddressChange = (addressType, field, value) => {
    // console.log('addressType=>', addressType);
    // console.log('field=>', field);
    // console.log('value=>', value);

    const setAddress =
      addressType === 'present' ? setPresentAddress : setFamilyAddress;
    const addressStateKey = addressType === 'present' ? 'present' : 'family';

    if (field === 'country') {
      if (!value) {
        if (addressType === 'present') {
          setStatesArrayPresent(null);
          setCityArrayPresent(null);
        } else {
          setStatesArrayFamily(null);
          setCityArrayFamily(null);
        }
        setAddress((prevState) => ({
          ...prevState,
          state: '',
          city: '',
        }));
      }
      if (addressType === 'present') {
        selectedCountryPre = countryData.find((country) => {
          return country.id === parseInt(value);
        });
      } else {
        selectedCountryFam = countryData.find((country) => {
          return country.id === parseInt(value);
        });
      }
      // console.log('selectedCountryPre: ', selectedCountryPre);

      if (selectedCountryPre) {
        const { country_code } = selectedCountryPre;
        // console.log('country_code', country_code);

        let newStatesArray = statesData.filter(
          (state) => state.country_code === country_code
        );
        // console.log(newStatesArray);
        newStatesArray = newStatesArray.map((state) => {
          const newState = { value: state.stcode, ...state };
          return newState;
        });

        // console.log(newStatesArray);
        if (addressType === 'present') {
          setStatesArrayPresent(newStatesArray);
        } else {
          setStatesArrayFamily(newStatesArray);
        }
      }
    }

    if (field === 'state') {
      addressType === 'present'
        ? setSelectedCityPresent(null)
        : setSelectedCityFamily(null);
      if (addressType === 'present') {
        setCityArrayPresent(null);
        setAddress((prevState) => ({
          ...prevState,
          city: '',
        }));
      }
      if (addressType === 'family') {
        setCityArrayFamily(null);
        setAddress((prevState) => ({
          ...prevState,
          city: '',
        }));
      }

      // console.log('addressType::', addressType);
      if (!value) {
        if (addressType === 'present') {
          setCityArrayPresent(null);
          setAddress((prevState) => ({
            ...prevState,
            city: '',
          }));
        } else {
          setCityArrayFamily(null);
          setAddress((prevState) => ({
            ...prevState,
            city: '',
          }));
        }
      }
      // console.log(value);

      const selectedState = statesData.find((state) => state.stcode === value);
      // console.log('selectedState: ', selectedState);

      if (selectedState) {
        const { stcode } = selectedState;
        const newCityArray = cityData.filter((city) => city.stcode === stcode);
        // console.log('newCityArray: ', newCityArray);
        if (addressType === 'present') {
          setCityArrayPresent(newCityArray);
        } else {
          setCityArrayFamily(newCityArray);
        }
      }
    }

    // console.log(field, value);
    if (field === 'city') {
      setSelectedCityPresent(null);
    }
    // console.log(field, value);

    setAddress((prevState) => ({ ...prevState, [field]: value }));
    setTouched((prevTouched) => ({
      ...prevTouched,
      [addressStateKey]: { ...prevTouched[addressStateKey], [field]: true },
    }));
  };

  const handleSameAsAboveChange = (e) => {
    setSameAsAbove(e.target.checked);
    if (e.target.checked) {
      setFamilyAddress(presentAddress);
    }
  };

  const validateInputs = () => {
    const errors = {};
    let isValid = true;

    const validateAddress = (address, prefix) => {
      Object.keys(address).forEach((field) => {
        const error = validateField(field, address[field]);
        if (error) {
          errors[`${prefix}${field.charAt(0).toUpperCase() + field.slice(1)}`] =
            error;
          isValid = false;
        }
      });
    };

    validateAddress(presentAddress, 'present');

    if (!sameAsAbove) {
      validateAddress(familyAddress, 'family');
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleOnSave = async () => {
    setIsLoading(true);
    // console.log('presentAddress: ', presentAddress);
    // console.log('familyAddress: ', familyAddress);

    if (!validateInputs()) {
      setIsLoading(false);
      return;
    }

    try {
      const formDataToSubmit = {
        address: presentAddress.address,
        country: presentAddress.country,
        state: presentAddress.state,
        city: presentAddress.city,
        pincode: presentAddress.pincode,
        family_address: familyAddress.address,
        family_country: familyAddress.country,
        family_state: familyAddress.state,
        family_city: familyAddress.city,
        family_pincode: familyAddress.pincode,
      };
      console.log('formDataToSubmit: ', formDataToSubmit);
      const updatedData = await dispatch(
        updateUserAddressEditProfile(formDataToSubmit)
      );
      setSaveSuccess(true);
      console.log('updatedData: ', updatedData);

      // props.onShowEditSuccess('Save!');
    } catch (err) {
      console.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <EditProfileLayout onShowSignUp={props.onShowSignUp} editPage='address'>
      <div className='flex flex-col justify-center items-center w-full h-full'>
        <SectionTitle title='Present Address'>
          <AddressFields
            addressData={presentAddress}
            selectedCountry={selectedCountryPre}
            selectedState={selectedStatePresent}
            selectedCity={selectedCityPresent}
            countryOptions={countryOptionsPresent}
            stateOptions={statesArrayPresent}
            cityOptions={cityArrayPresent}
            onAddressChange={(field, value) =>
              handleAddressChange('present', field, value)
            }
            errors={formErrors}
            prefix='present'
          />
        </SectionTitle>
        <SectionTitle title='Family Address'>
          <div className='flex justify-start items-center w-full'>
            <input
              type='checkbox'
              id='sameasabove'
              className='cursor-pointer'
              checked={sameAsAbove}
              onChange={handleSameAsAboveChange}
            />
            <label
              htmlFor='sameasabove'
              className='cursor-pointer font-medium text-[14px] ml-1 text-[#858585]'
            >
              Same as above
            </label>
          </div>
          {!sameAsAbove && (
            <AddressFields
              addressData={familyAddress}
              selectedCountry={selectedCountryFam}
              selectedState={selectedStateFamily}
              selectedCity={selectedCityFamily}
              countryOptions={countryOptionsFamily}
              stateOptions={statesArrayFamily}
              cityOptions={cityArrayFamily}
              onAddressChange={(field, value) =>
                handleAddressChange('family', field, value)
              }
              errors={formErrors}
              prefix='family'
            />
          )}
        </SectionTitle>
        <div className='flex justify-start items-center my-5 w-full'>
          <Button
            onButtonClick={handleOnSave}
            text={isLoading ? 'Loading...' : 'Save'}
            className='font-bold w-[130px] h-[50px]'
            disabled={isLoading}
          >
            {isLoading && (
              <div className='flex justify-center items-center bg-white rounded-full ml-2'>
                <svg
                  className='animate-spin h-6 w-6 text-pink-500'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-25'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  ></circle>
                  <path
                    className='opacity-75'
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8v8H4z'
                  ></path>
                </svg>
              </div>
            )}
          </Button>
          {saveSuccess && (
            <span className='flex justify-center items-center font-semibold text-[#333]'>
              <FaCheckCircle className='mr-2 text-[20px] text-[#4CAF50] ml-2' />
              saved
            </span>
          )}
        </div>
      </div>
    </EditProfileLayout>
  );
};

const SectionTitle = ({ title, children }) => (
  <div className='flex flex-col justify-center items-center w-full h-full font-bold text-[18px] text-[#FF4061]'>
    <div className='flex justify-start items-center w-full font-bold text-[18px] text-[#FF4061]'>
      {title}
    </div>
    {children}
  </div>
);

const AddressFields = ({
  addressData,
  countryOptions,
  stateOptions,
  cityOptions,
  selectedCountry,
  selectedState,
  selectedCity,
  onAddressChange,
  errors,
  prefix,
}) => (
  <div className='w-full'>
    <div className='flex flex-col md:flex-row justify-center md:justify-between items-start w-full'>
      <div className='flex justify-center items-center w-full md:w-full'>
        <Input
          id={`${prefix}Address`}
          type='textarea'
          placeholder='Enter Address'
          label='Address:'
          className='w-full'
          value={addressData.address}
          onChange={(e) => onAddressChange('address', e.target.value)}
          error={errors[`${prefix}Address`]}
        />
      </div>
    </div>
    <div className='flex flex-col md:flex-row justify-center md:justify-between items-start w-full'>
      <div className='flex justify-center items-center w-full md:w-[49%]'>
        <SelectInput
          id={`${prefix}Country`}
          label='Country:'
          options={countryOptions}
          className='font-semibold cursor-pointer'
          width='w-full'
          value={addressData.country}
          selectType='id'
          select={selectedCountry || ''}
          onChange={(e) => onAddressChange('country', e.target.value)}
          error={errors[`${prefix}Country`]}
        />
      </div>
      <div className='flex justify-center items-center w-full md:w-[49%]'>
        <SelectInput
          id={`${prefix}State`}
          label='State:'
          options={stateOptions}
          selectType='stcode'
          className='font-semibold cursor-pointer'
          width='w-full'
          value={addressData.state}
          select={selectedState || ''}
          onChange={(e) => onAddressChange('state', e.target.value)}
          error={errors[`${prefix}State`]}
        />
      </div>
    </div>
    <div className='flex flex-col md:flex-row justify-center md:justify-between items-start w-full'>
      <div className='flex justify-center items-center w-full md:w-[49%]'>
        <SelectInput
          id={`${prefix}City`}
          name='city'
          label='City:'
          selectType='id'
          options={cityOptions}
          className='w-full'
          value={addressData.city}
          select={selectedCity || ''}
          onChange={(e) => onAddressChange('city', e.target.value)}
          error={errors[`${prefix}City`]}
        />
      </div>
      <div className='flex justify-center items-center w-full md:w-[49%]'>
        <Input
          id={`${prefix}Pincode`}
          label='Pincode:'
          placeholder='Enter pincode'
          className=''
          width='w-full'
          value={addressData.pincode}
          onChange={(e) => onAddressChange('pincode', e.target.value)}
          error={errors[`${prefix}Pincode`]}
        />
      </div>
    </div>
  </div>
);

export default AddressProfile;
