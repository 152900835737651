import { useDispatch } from 'react-redux';
import Button from '../../../components/UI/Button/Button';
import defaultProfileIcon from './images/defaultProfileIcon.png';
import { useEffect, useState } from 'react';
import { getCompareUserDetails } from '../../../redux/features/userSlice';
import { useNavigate } from 'react-router-dom';

const UserProfilePictureData = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState();
  const [profileImageUrl, setProfileImageUrl] = useState();

  // const images = useSelector((state) => state.image.profilePhotoImages);
  // let img = images && images[0] && images[0].url;
  // const fullImageUrl = img ? 'https://api.dev.sanroj.com' + img : '';
  // useEffect(() => {
  //   if (fullImageUrl) {
  //     setProfilePicUrl(fullImageUrl);
  //   }
  // }, [fullImageUrl]);

  // console.log(props.profilePicture);
  // console.log(props.profilePicture);

  useEffect(() => {
    if (props.profilePicture) {
      const fullImageUrl = props.profilePicture?.url
        ? 'https://api.dev.sanroj.com' + props.profilePicture?.url
        : '';
      setProfileImageUrl(fullImageUrl);
    }
  }, [props.profilePicture]);

  const onMatchNowHandler = async (profile_id) => {
    setIsLoading(true);
    console.log(profile_id);
    await dispatch(getCompareUserDetails(profile_id));
    setIsLoading(false);
    navigate('/view-profile/match-now');
    // navigate('/match-now');
  };

  return (
    <div className='flex flex-col justify-center items-center w-full lg:w-[34%]'>
      <div className='aspect-square relative w-full md:w-[400px] lg:w-full'>
        <img
          src={profileImageUrl || defaultProfileIcon}
          // src={ProfilePicUrl || defaultProfileIcon}
          alt='profile-pic'
          className='object-cover absolute w-full rounded-t-md'
        />
      </div>
      <div className='flex justify-between items-center w-full'>
        <Button
          text='Chat Now'
          className='px-4 py-8 rounded-none w-[50%] text-[16px] md:text-[18px] lg:text-[20px] text-white font-semibold  opacity-60 cursor-not-allowed'
          // className='py-8 rounded-none w-[50%] text-[16px] md:text-[18px] lg:text-[24px] font-extrabold'
          bgBtn='bg-[#333333]'
          disabled={true}
        />
        <Button
          text='Match Now'
          className='py-8 rounded-none w-[50%] text-[16px] md:text-[18px] lg:text-[20px] font-semibold'
          bgBtn='bg-[#FFC0CB]'
          onButtonClick={() => {
            onMatchNowHandler(props.profile_id);
          }}
        >
          {isLoading && (
            <div className='flex justify-center items-center bg-white rounded-full ml-2'>
              <svg
                className='animate-spin h-6 w-6 text-pink-500'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
              >
                <circle
                  className='opacity-25'
                  cx='12'
                  cy='12'
                  r='10'
                  stroke='currentColor'
                  strokeWidth='4'
                ></circle>
                <path
                  className='opacity-75'
                  fill='currentColor'
                  d='M4 12a8 8 0 018-8v8H4z'
                ></path>
              </svg>
            </div>
          )}
        </Button>
      </div>
    </div>
  );
};

export default UserProfilePictureData;
