import ProfileCard from '../../components/UI/ProfileCard/ProfileCard';

const AllProfiles = ({ allMatchingProfileData, className }) => {
  // console.log('allMatchingProfileData: ', allMatchingProfileData);

  return (
    <div className='flex flex-col md:flex-row flex-wrap justify-center md:justify-between items-center w-[90%]'>
      {allMatchingProfileData &&
        allMatchingProfileData.map((data, idx) => (
          <ProfileCard key={idx} profileData={data} className={className} />
        ))}
    </div>
  );
};
export default AllProfiles;
