const PrivacyPolicy = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  return (
    <section className=''>
      <div className='mt-20 pb-20'>
        <div className='py-14 bg-[#FF8C9C]'>
          <p className='font-bold px-20 text-white text-[24px]'>
            Privacy Policy
          </p>
        </div>
        <div className='flex flex-col justify-start items-center py-10 px-20'>
          <div className='flex flex-col gap-5 text-[18px]'>
            <p className='font-medium tracking-wider flex flex-col gap-3'>
              You may return most new, unopened items within 30 days of delivery
              for a full refund. We'll also pay the return shipping costs if the
              return is a result of our error you received an incorrect or
              defective item, etc.. <p />
              You should expect to receive your refund within four weeks of
              giving your package to the return shipper, however, in many cases
              you will receive a refund more quickly. This time period includes
              the transit time for us to receive your return from the shipper (5
              to 10 business days), the time it takes us to process your return
              once we receive it (3 to 5 business days), and the time it takes
              your bank to process our refund request (5 to 10 business days).{' '}
              <p />
              If you need to return an item, simply login to your account, view
              the order using the 'Complete Orders' link under the My Account
              menu and click the Return Item(s) button. We'll notify you via
              e-mail of your refund once we've received and processed the
              returned item.
            </p>
          </div>
        </div>
        <div className='flex flex-col justify-start items-center py-10 px-20'>
          <div className='flex flex-col gap-5 text-[18px]'>
            <p className='font-medium tracking-wider flex flex-col gap-3'>
              You may return most new, unopened items within 30 days of delivery
              for a full refund. We'll also pay the return shipping costs if the
              return is a result of our error you received an incorrect or
              defective item, etc.. <p />
              You should expect to receive your refund within four weeks of
              giving your package to the return shipper, however, in many cases
              you will receive a refund more quickly. This time period includes
              the transit time for us to receive your return from the shipper (5
              to 10 business days), the time it takes us to process your return
              once we receive it (3 to 5 business days), and the time it takes
              your bank to process our refund request (5 to 10 business days).{' '}
              <p />
              If you need to return an item, simply login to your account, view
              the order using the 'Complete Orders' link under the My Account
              menu and click the Return Item(s) button. We'll notify you via
              e-mail of your refund once we've received and processed the
              returned item.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
