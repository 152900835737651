import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/UI/Button/Button';
// import DashboardLayout from '../../DashboardLayout';
import SelectInput from '../../../../components/UI/SelectInput/SelectInput';
import { useEffect, useState } from 'react';
import { getUserFullDetails } from '../../../../redux/features/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../../../components/UI/Input/Input';
import {
  getDefaultSearchFormFields,
  getFindMatchProfiles,
} from '../../../../redux/features/profileSlice';

const SearchProfile = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.user.userInfo);
  // console.log(userInfo);

  const { gender } = userInfo?.additional_details || {};
  // console.log(gender);

  const cityData = useSelector((state) => state.field.city);

  // const userAge = 23; // Assuming the logged-in user's age is 23
  // const maxAge = 30; // The maximum age you want in the array
  // const ageArray = Array.from({ length: maxAge - userAge + 1 }, (_, i) => ({
  //   name: userAge + i,
  // }));

  const [isProfileIDLoading, setIsProfileIDLoading] = useState(false);
  const [isFindMtchLoading, setIsFindMtchLoading] = useState(false);

  const [profileId, setProfileId] = useState('');
  const [profileIdError, setProfileIdError] = useState('');
  const [profileIdTouched, setProfileIdTouched] = useState(false);

  const [ageArray, setAgeArray] = useState([]);
  const [formData, setFormData] = useState({
    imLookingFor: '',
    ageFrom: '',
    ageTo: '',
    location: null,
  });

  useEffect(() => {
    // Set age options based on gender
    if (gender) {
      const minAge = gender === 'Male' ? 18 : 21;
      const maxAge = 40;
      const ages = Array.from({ length: maxAge - minAge + 1 }, (_, i) => ({
        name: minAge + i,
      }));
      setAgeArray(ages);
    }
  }, [gender]);

  useEffect(() => {
    const fetchdefaultSearchFormField = async () => {
      const responseFields = await dispatch(getDefaultSearchFormFields());
      const defaultFields = responseFields?.payload;
      // console.log(responseFields.payload);
      if (defaultFields) {
        setFormData((prevData) => ({
          ...prevData,
          imLookingFor: defaultFields.gender || '',
          ageFrom: defaultFields.from_age || '',
          ageTo: defaultFields.to_age || '',
          location: '' || defaultFields.location || '',
        }));
      }
    };
    fetchdefaultSearchFormField();
  }, [dispatch]);

  // const [formErrors, setFormErrors] = useState({
  //   imLookingFor: '',
  //   ageFrom: '',
  //   ageTo: '',
  //   location: '',
  // });

  // const [touched, setTouched] = useState({
  //   imLookingFor: false,
  //   ageFrom: false,
  //   ageTo: false,
  //   location: false,
  // });

  // useEffect(() => {
  //   if (touched.imLookingFor) {
  //     if (!formData.imLookingFor) {
  //       setFormErrors((prevErrors) => ({
  //         ...prevErrors,
  //         imLookingFor: `Please select i'm looking for`,
  //       }));
  //     } else {
  //       setFormErrors((prevErrors) => ({
  //         ...prevErrors,
  //         imLookingFor: '',
  //       }));
  //     }
  //   }
  // }, [formData.imLookingFor, touched.imLookingFor]);

  // useEffect(() => {
  //   if (touched.ageFrom) {
  //     if (!formData.ageFrom) {
  //       setFormErrors((prevErrors) => ({
  //         ...prevErrors,
  //         ageFrom: `Please select i'm looking for`,
  //       }));
  //     } else {
  //       setFormErrors((prevErrors) => ({
  //         ...prevErrors,
  //         ageFrom: '',
  //       }));
  //     }
  //   }
  // }, [formData.ageFrom, touched.ageFrom]);

  // useEffect(() => {
  //   if (touched.ageTo) {
  //     if (!formData.ageTo) {
  //       setFormErrors((prevErrors) => ({
  //         ...prevErrors,
  //         ageTo: `Please select i'm looking for`,
  //       }));
  //     } else {
  //       setFormErrors((prevErrors) => ({
  //         ...prevErrors,
  //         ageTo: '',
  //       }));
  //     }
  //   }
  // }, [formData.ageTo, touched.ageTo]);

  // useEffect(() => {
  //   if (touched.location) {
  //     if (!formData.location) {
  //       setFormErrors((prevErrors) => ({
  //         ...prevErrors,
  //         location: `Please select i'm looking for`,
  //       }));
  //     } else {
  //       setFormErrors((prevErrors) => ({
  //         ...prevErrors,
  //         location: '',
  //       }));
  //     }
  //   }
  // }, [formData.location, touched.location]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    console.log(id, value);

    if (value === 'Select') {
      setFormData((prevData) => ({
        ...prevData,
        [id]: '',
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
    // setTouched((prevTouched) => ({
    //   ...prevTouched,
    //   [id]: true,
    // }));
  };

  // const validateInputs = () => {
  //   let valid = true;
  //   const errors = {};

  //   if (!formData.imLookingFor) {
  //     errors.imLookingFor = `Date of i'm looking for is required.`;
  //     valid = false;
  //   }
  //   if (!formData.ageFrom) {
  //     errors.ageFrom = 'Time of age from is required.';
  //     valid = false;
  //   }
  //   if (!formData.ageTo) {
  //     errors.ageTo = 'Manglic age to is required.';
  //     valid = false;
  //   }
  //   if (!formData.location) {
  //     errors.location = 'location is required.l';
  //     valid = false;
  //   }
  //   setFormErrors(errors);
  //   return valid;
  // };

  const handleOnFindYourMatch = async () => {
    setIsFindMtchLoading(true);
    // setError(null);

    // if (!validateInputs()) {
    //   setIsFindMtchLoading(false);
    //   return;
    // }

    try {
      // console.log(formData);

      const formDataToSubmit = {
        gender: formData.imLookingFor,
        from_age: formData.ageFrom,
        to_age: formData.ageTo,
        location: formData.location,
      };

      if (!formDataToSubmit.location) {
        delete formDataToSubmit.location;
      }

      console.log(formDataToSubmit);

      const data = await dispatch(getFindMatchProfiles(formDataToSubmit));
      // setSaveSuccess(true);
      console.log('data: ', data);
      // Post data to API
    } catch (err) {
      // setError(err.message);
      console.log(err);
    } finally {
      setIsFindMtchLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setProfileId(e.target.value);
    setProfileIdTouched(true);
  };

  const validateProfileID = () => {
    if (!profileId) {
      setProfileIdError('Please enter a profile ID.');
      return false;
    }
    setProfileIdError('');
    return true;
  };

  useEffect(() => {
    if (profileId) {
      setProfileIdError('');
    }
  }, [profileIdTouched, profileId]);

  const handleSearchClick = async () => {
    if (!validateProfileID()) {
      return;
    }

    setIsProfileIDLoading(true);
    const data = await dispatch(getUserFullDetails(profileId));
    console.log(data?.payload);

    setIsProfileIDLoading(false);
    navigate(`/view-profile/${profileId}`);
  };

  return (
    // <DashboardLayout activePage="findprofile" onShowSignUp={props.onShowSignUp}>
    <div className='flex flex-col justify-start items-center w-full max-h-[540px] overflow-y-scroll py-4'>
      <div className='flex flex-col justify-start items-start w-full border-2 border-[#FFC0CB] p-4 rounded-md md:py-5'>
        <p className='text-[16px] font-bold text-[#333333] mb-3'>
          Serach By Profile ID
        </p>
        <div className='flex flex-col md:flex-row justify-center md:justify-start items-center md:gap-5 w-full '>
          <div className='flex flex-col justify-between items-center w-full md:w-[49%]'>
            <Input
              id='profileId'
              type='text'
              // label='Profile Id:'
              placeholder='Enter profile id'
              value={profileId}
              onChange={handleInputChange}
              className='font-semibold'
              width='w-full'
              error={profileIdError}
            />
          </div>
          <div className='flex justify-center items-center mt-8 md:mt-2'>
            <Button
              text='Search Profile'
              className='h-[50px] font-bold w-full md:w-auto'
              onButtonClick={handleSearchClick}
            >
              {isProfileIDLoading && (
                <div className='flex justify-center items-center bg-white rounded-full ml-2'>
                  <svg
                    className='animate-spin h-6 w-6 text-pink-500'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                  >
                    <circle
                      className='opacity-25'
                      cx='12'
                      cy='12'
                      r='10'
                      stroke='currentColor'
                      strokeWidth='4'
                    ></circle>
                    <path
                      className='opacity-75'
                      fill='currentColor'
                      d='M4 12a8 8 0 018-8v8H4z'
                    ></path>
                  </svg>
                </div>
              )}
            </Button>
          </div>
        </div>
      </div>
      <span className='bg-[#CCCCCC] w-full h-[1px] my-6'></span>
      <div className='flex flex-col justify-between items-start w-full border-2 border-[#FFC0CB] p-4 rounded-md md:py-5'>
        <p className='text-[16px] font-bold text-[#333333]'>Find Your Match</p>
        <div className='flex flex-wrap flex-col md:flex-row justify-between items-center w-full mt-5'>
          <SelectInput
            id='imlookingfor'
            label="I'm looking for:"
            value={formData.imLookingFor}
            onChange={handleChange}
            select={formData.imLookingFor}
            disabled={true}
            // options={[{ name: 'Male' }, { name: 'Female' }]}
            className='!bg-[#FFC0CBFF] focus:outline-none !outline-transparent border-none font-medium text-[#FFFFFF] opacity-60 cursor-not-allowed'
            width='w-full md:w-[48%] lg:w-[24%] text-[#FFFFFF]'
            // error={formErrors.imLookingFor}
          />
          <SelectInput
            id='ageFrom'
            label='Age From'
            value={formData.ageFrom}
            onChange={handleChange}
            options={ageArray}
            className='!bg-[#FFC0CBFF] focus:outline-none !outline-transparent border-none font-medium text-[#FFFFFF]'
            width='w-full md:w-[48%] lg:w-[24%] text-[#FFFFFF]'
            // error={formErrors.ageFrom}
          />
          <SelectInput
            id='ageTo'
            label='Age To'
            value={formData.ageTo}
            onChange={handleChange}
            options={ageArray}
            className='!bg-[#FFC0CBFF] focus:outline-none !outline-transparent border-none font-medium text-[#FFFFFF]'
            width='w-full md:w-[48%] lg:w-[24%] text-[#FFFFFF]'
            // error={formErrors.ageTo}
          />

          <SelectInput
            id='location'
            label='Location:'
            select='All'
            value={formData.location}
            onChange={handleChange}
            options={cityData}
            className='!bg-[#FFC0CBFF] focus:outline-none !outline-transparent border-none font-medium text-[#FFFFFF]'
            width='w-full md:w-[48%] lg:w-[24%] text-[#FFFFFF]'
            // error={formErrors.location}
          />
        </div>
        <div className='flex justify-center items-center w-full mt-8'>
          {/* <Link to='/findprofile' className='flex justify-center items-center'> */}
          <Button
            text='Find Your Match'
            className='h-[50px] w-full md:w-[200px] font-bold'
            onButtonClick={handleOnFindYourMatch}
          >
            {isFindMtchLoading && (
              <div className='flex justify-center items-center bg-white rounded-full ml-2'>
                <svg
                  className='animate-spin h-6 w-6 text-pink-500'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-25'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  ></circle>
                  <path
                    className='opacity-75'
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8v8H4z'
                  ></path>
                </svg>
              </div>
            )}
          </Button>
          {/* </Link> */}
        </div>
      </div>
    </div>
    // </DashboardLayout>
  );
};
export default SearchProfile;
