import { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import AppRoutes from './Routes';
import Signup from './pages/Signup/Signup';
import Signin from './pages/Signin/Signin';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ProfileCompleteStatus from './pages/ProfileForm/ProfileCompleteStatus/ProfileCompleteStatus';
import Footer from './components/Footer/Footer';
import SuccessMessage from './components/UI/SuccessMessage/SuccessMessage';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMatchingProfiles,
  getUserProfile,
} from './redux/features/userSlice';
import Loading from './components/loading/Loading';

import {
  fetchCountry,
  fetchStates,
  fetchCity,
  fetchReligion,
  fetchCommunity,
  fetchSubCommunity,
  fetchWeight,
  fetchMotherTounge,
  fetchQualificationType,
  fetchHighestQualification,
  fetchOccupation,
  fetchAnnualIncome,
  fetchPhysicalStatus,
  fetchDietaryPreferences,
} from './redux/features/fieldsSlice';
import { fetchCompanyInfo } from './redux/features/companyMasterSlice';
import {
  fetchAllImages,
  fetchImageType,
  fetchprofilePicUrlMyDetailByProfileId,
} from './redux/features/imageSlice';
import Navbar from './components/Navbar/Navbar';

function App() {
  const [activeModal, setActiveModal] = useState(null);
  const [successMessageText, setSuccessMessageText] = useState('');
  const dispatch = useDispatch();

  const token = localStorage.getItem('auth_token');

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (token) {
          const userProfileData = await dispatch(getUserProfile());
          await dispatch(getMatchingProfiles());
          // console.log('userProfileData', userProfileData?.payload?.profile_id);
          const profile_id = userProfileData?.payload?.profile_id;
          if (profile_id) {
            await dispatch(fetchprofilePicUrlMyDetailByProfileId(profile_id));
          }
        }
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };
    fetchData();
  }, [token, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchImageType());
        await dispatch(fetchCompanyInfo({ typeId: 2 }));
        await dispatch(fetchCountry());
        await dispatch(fetchStates());
        await dispatch(fetchCity());
        await dispatch(fetchReligion());
        await dispatch(fetchCommunity());
        await dispatch(fetchSubCommunity());
        await dispatch(fetchWeight());
        await dispatch(fetchMotherTounge());
        await dispatch(fetchQualificationType());
        await dispatch(fetchHighestQualification());
        await dispatch(fetchOccupation());
        await dispatch(fetchAnnualIncome());
        await dispatch(fetchPhysicalStatus());
        await dispatch(fetchDietaryPreferences());
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };
    fetchData();
  }, [dispatch]);

  const imageTypes = useSelector((state) => state.image.imageTypes);
  useEffect(() => {
    if (token && imageTypes.length > 0) {
      dispatch(fetchAllImages());
    }
  }, [token, dispatch, imageTypes]);

  const showModal = (modal) => setActiveModal(modal);
  const hideModal = () => setActiveModal(null);

  const showSuccessMessageWithText = (text) => {
    setSuccessMessageText(text);
    showModal('successmessage');
  };

  return (
    <>
      {/* <GoogleReCaptchaProvider reCaptchaKey={site_key}> */}
      <Loading />
      <Router>
        <Navbar onShowSignUp={() => showModal('signup')} />
        {activeModal === 'signup' && (
          <Signup
            onShowSignIn={() => showModal('signin')}
            onHideSignup={hideModal}
            onShowSuccessMessage={showSuccessMessageWithText}
          />
        )}
        {activeModal === 'signin' && (
          <Signin
            onShowSignUp={() => showModal('signup')}
            onHideSignin={hideModal}
            onShowForgotPassword={() => showModal('forgotPassword')}
            onShowSuccessMessage={showSuccessMessageWithText}
          />
        )}
        {activeModal === 'forgotPassword' && (
          <ForgotPassword
            onShowSuccessMessage={showSuccessMessageWithText}
            onHideForgotPassword={hideModal}
          />
        )}
        {activeModal === 'profilecompletestatus' && (
          <ProfileCompleteStatus onHideProfileCompleteStatus={hideModal} />
        )}
        {activeModal === 'successmessage' && (
          <SuccessMessage
            successMessage={successMessageText}
            onHideSuccessMessage={hideModal}
          />
        )}
        <AppRoutes
          onShowBasicInfo={() => showModal('basicinfo')}
          onShowSignUp={() => showModal('signup')}
          onShowSuccessMessage={() => showModal('successmessage')}
        />
        <Footer isLoggedIn={!!token} />
      </Router>
      {/* </GoogleReCaptchaProvider> */}
    </>
  );
}

export default App;
