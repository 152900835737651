/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import DashboardLayout from '../../DashboardLayout';
import PhotoAlbumCard from './PhotoAlbumCard';
import { useEffect, useState } from 'react';
import {
  applyWatermark,
  getCompressedImage,
  getCroppedImg,
  handleCropComplete,
} from '../../../../utils/imageUtils';
import {
  removeImage,
  uploadImage,
} from '../../../../redux/features/imageSlice';
import { AiOutlineLoading } from 'react-icons/ai';
import Cropper from 'react-easy-crop';
import { IoCloseSharp } from 'react-icons/io5';
import { Carousel } from 'react-responsive-carousel';
import Modal from '../../../../components/UI/Modal/Modal';
import { MdError } from 'react-icons/md';

const PhotoAlbum = (props) => {
  const dispatch = useDispatch();
  const [isRemoveLoading, setIsRemoveLoading] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [albumPicUrl, setAlbumPicUrl] = useState(null);
  const [albumImages, setAlbumImages] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [isCropping, setIsCropping] = useState(false);
  const [isFourImages, setIsFourImages] = useState(false);

  const albumImagesFromSlice = useSelector(
    (state) => state.image.photoAlbumImages
  );

  const handleImageClick = (idx) => {
    setCurrentImageIndex(idx);
    setIsPopupOpen(true);
  };

  const hideImagePopup = () => {
    setIsPopupOpen(false);
  };

  const changeAlbumImageHandler = (image_id) => {
    console.log('changeAlbumImageHandler', image_id);
  };
  const removeAlbumImageHandler = async (image_id) => {
    setIsRemoveLoading((prevState) => ({ ...prevState, [image_id]: true })); // console.log('removeAlbumImageHandler', image_id);
    await dispatch(removeImage({ image_id }));
    setIsRemoveLoading((prevState) => ({ ...prevState, [image_id]: false }));
  };

  // console.log(albumImagesFromSlice);

  useEffect(() => {
    const lastFourImage = albumImagesFromSlice?.slice(-4);
    setAlbumImages(lastFourImage);
    // console.log(lastFourImage);
    if (albumImages?.length === 4) {
      setIsFourImages(true);
      // console.log('isTwoImages:', isTwoImages);
    }
  }, [albumImagesFromSlice]);
  // console.log('albumImages: ', albumImages);

  const handleProfilePicChange = async (e) => {
    if (isCropping) return;
    if (isFourImages) {
      return;
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });

    let file = e.target.files[0];
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];

    if (file && allowedTypes.includes(file.type)) {
      const reader = new FileReader();
      reader.onload = () => setSelectedImage(reader.result);
      reader.readAsDataURL(file);
    } else {
      // setFileName('');
      setAlbumPicUrl('');
    }
    setIsLoading(false);
  };

  const saveCroppedImage = async () => {
    if (isCropping) return;

    setIsCropping(true);
    setIsLoading(true);

    try {
      // Step 1: Crop the image
      console.log('crop');
      const croppedImage = await getCroppedImg(
        selectedImage,
        crop,
        croppedAreaPixels
      );

      // Step 2: Apply watermark to the cropped image
      const watermarkedImage = await applyWatermark(croppedImage);

      // Step 3: Compress the image after adding the watermark
      const compressedImage = await getCompressedImage(watermarkedImage);
      // console.log(compressedImage.size / 1024 / 1024);

      // Step 4: Convert to Blob URL and set to state/form data
      const BLOB_URL = URL.createObjectURL(compressedImage);
      setAlbumPicUrl(BLOB_URL);

      // console.log(typeof BLOB_URL, 'dispach');

      // Reset cropper after saving

      setIsUploading(false);
      await dispatch(uploadImage({ image: compressedImage, typeId: 3 }));
      setSelectedImage(null);
      setAlbumPicUrl(null);
    } catch (error) {
      console.error('Error cropping or processing image:', error);
    } finally {
      setIsCropping(false);
      setIsUploading(false);
    }
    setIsLoading(false);
  };

  return (
    <DashboardLayout activePage='photoalbum' onShowSignUp={props.onShowSignUp}>
      <div className='flex flex-col justify-center items-start w-full md:w-[95%] relative'>
        <div className='flex justify-between md:justify-start items-center gap-3 w-full'>
          <p className='font-extrabold text-[26px] text-[#FF4061]'>
            Photo Album
          </p>
          {albumImages && albumImages.length <= 3 ? (
            <div className='flex justify-center items-center'>
              <label
                htmlFor='profilePic'
                className='flex justify-center items-center h-[45px] px-4 rounded-md font-bold text-white bg-[#FF4061CC] hover:bg-[#FF4061] cursor-pointer active:border-[#808080] border-2 border-[transparent]'
              >
                Add
              </label>
              <input
                id='profilePic'
                type='file'
                className='hidden'
                accept='image/*'
                onChange={handleProfilePicChange}
              />
            </div>
          ) : (
            <p className='flex justify-start items-center border-2 border-[#FF4061] bg-orange-100 text-orange-500 text-[14px] p-1'>
              <MdError className='mr-2 text-[20px] text-orange-500' />
              Profile Upload Limit Reached
            </p>
          )}
        </div>
        <span className='bg-[#CCCCCC] w-full h-[1px] my-3'></span>
        {selectedImage && (
          <div className='flex justify-center w-[100%] translate-x-[-50%] left-[50%] top-0 bg-[rgba(0,0,0,0.57)] absolute z-50 shadow rounded-md p-4'>
            <div className='w-[90%] md:w-[50%] lg:w-[30%] md:top-0 bg-white shadow rounded-md p-2'>
              <div className='flex justify-between items-center p-2'>
                <button
                  className='flex justify-center items-center bg-red-500 lg:!text-[18px] lg:!font-bold  text-[#FFFFFF] border-2 px-4 py-1 bottom-0 rounded-lg font-bold'
                  onClick={() => {
                    setSelectedImage(null);
                  }}
                >
                  close
                </button>
                <button
                  className={`flex justify-center items-center ${
                    isUploading ? 'bg-green-500' : 'bg-black'
                  } lg:!text-[18px] lg:!font-semibold  text-[#FFFFFF] border-2 px-4 py-1 bottom-0 rounded-lg font-bold`}
                  onClick={saveCroppedImage}
                >
                  {isUploading ? 'saving..' : 'crop'}
                  {isLoading && (
                    <AiOutlineLoading className='animate-spin ml-2' />
                  )}
                </button>
              </div>
              <div className='relative'>
                <div
                  className={`flex flex-col justify-center items-center ative py-5 p-4 w-[250px] md:w-[500px] lg:w-[700px] h-[250px] md:h-[400px]`}
                >
                  <div className='cropper-containe'>
                    <Cropper
                      image={selectedImage}
                      crop={crop}
                      zoom={zoom}
                      aspect={1}
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                      onCropComplete={(croppedArea, croppedAreaPixels) =>
                        handleCropComplete(
                          croppedAreaPixels,
                          setCroppedAreaPixels
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <ul className='flex flex-wrap justify-center md:justify-between lg:justify-start lg:gap-[2.6%] items-center w-full'>
          {albumImages &&
            albumImages.map((image, idx) => (
              <PhotoAlbumCard
                isRemoveLoading={isRemoveLoading}
                key={image.id}
                imageUrl={'https://api.dev.sanroj.com' + image.url}
                changeAlbumImageHandler={() => {
                  changeAlbumImageHandler(image.id);
                }}
                removeAlbumImageHandler={() => {
                  removeAlbumImageHandler(image.id);
                }}
                imageId={image.id}
                handleImageClick={() => {
                  handleImageClick(idx);
                }}
              />
            ))}
        </ul>
        {isPopupOpen && (
          <Modal onHidePopup={hideImagePopup}>
            <div className='flex flex-col justify-center items-center relative py-3 md:p-4'>
              {/* <div className='flex flex-col justify-center items-center relative py-3 md:p-4 w-full lg:min-w-full bg-red-300'> */}
              <div className='flex justify-center items-center w-full'>
                <IoCloseSharp
                  onClick={hideImagePopup}
                  className='absolute active:text-[#333333] text-[#333333] hover:text-[#FF4061] cursor-pointer -right-1 -top-1 md:right-2 md:top-2'
                  size={28}
                />
              </div>
              <div className='relative flex flex-col justify-center items-center mt-8'>
                {/* <div className='relative flex flex-col justify-center items-center min:w-[60%] min:h-[60%] mt-8'> */}
                <Carousel
                  selectedItem={currentImageIndex}
                  showArrows={true}
                  showThumbs={false}
                  infiniteLoop={true}
                  className='w-[50%]'
                >
                  {albumImages &&
                    albumImages.map((image, index) => (
                      <img
                        key={index}
                        src={'https://api.dev.sanroj.com' + image.url}
                        alt={`Slide ${index}`}
                        className='w-[100%] rounded-md'
                      />
                    ))}
                </Carousel>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </DashboardLayout>
  );
  // return (
  //   <DashboardLayout activePage='photoalbum' onShowSignUp={props.onShowSignUp}>
  //     <div className='flex flex-col justify-center items-start w-full'>
  //       <div className='flex justify-start items-center w-full'>
  //         <p className='font-extrabold text-[26px] text-[#FF4061]'>
  //           Photo Album
  //         </p>
  //       </div>
  //       <span className='bg-[#CCCCCC] w-full h-[1px] my-3'></span>
  //       <ul className='flex flex-wrap justify-between items-center w-full'>
  //         {albums.map((image) => (
  //           <PhotoAlbumCard
  //             key={image.id}
  //             imageUrl={'https://api.dev.sanroj.com' + image.url}
  //             isEditable={true} // Mark existing images as editable
  //             imageId={image.id}
  //           />
  //         ))}

  //         {/* Render empty cards for new uploads if slots are available */}
  //         {[...Array(emptySlots)].map((_, index) => (
  //           <PhotoAlbumCard key={index} isEditable={false} />
  //         ))}
  //       </ul>
  //     </div>
  //   </DashboardLayout>
  // );
};

export default PhotoAlbum;
