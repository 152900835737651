import { FaCheckCircle } from "react-icons/fa";
import Button from "../../components/UI/Button/Button";
import Input from "../../components/UI/Input/Input";
import { useEffect, useState } from "react";
import { AiOutlineLoading } from "react-icons/ai";

const ForgotPasswordForm = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    phoneNumber: "",
    otp: "",
    newPassword: "",
  });

  const [formErrors, setFormErrors] = useState({
    phoneNumber: "",
    otp: "",
    newPassword: "",
  });

  const [touched, setTouched] = useState({
    phoneNumber: false,
    otp: false,
    newPassword: false,
  });

  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
    setTouched((prevTouched) => ({
      ...prevTouched,
      [id]: true,
    }));
  };

  useEffect(() => {
    if (touched.phoneNumber) {
      if (/[^0-9]/.test(formData.phoneNumber)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          phoneNumber: "Only digits are allowed!",
        }));
      } else if (
        formData.phoneNumber &&
        !/^\d{10}$/.test(formData.phoneNumber)
      ) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          phoneNumber: "Please enter a valid Phone number.",
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          phoneNumber: "",
        }));
      }
    }
  }, [formData.phoneNumber, touched.phoneNumber]);
  console.log(formErrors.phoneNumber);
  console.log(/[^0-9]/.test("ssd"));

  useEffect(() => {
    if (touched.otp) {
      if (/[^0-9]/.test(formData.otp)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          otp: "Only digits are allowed!",
        }));
      } else if (otpSent && formData.otp.length !== 4) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          otp: "Please enter a valid 4-digit OTP.",
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          otp: "",
        }));
      }
    }
  }, [formData.otp, otpSent, touched.otp]);

  useEffect(() => {
    if (touched.newPassword) {
      if (formData.newPassword && formData.newPassword.length < 8) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          newPassword: "New password must be at least 8 characters long.",
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          newPassword: "",
        }));
      }
    }
  }, [formData.newPassword, touched.newPassword]);

  const validateInputs = (data) => {
    let valid = true;
    const errors = {
      phoneNumber: "",
      otp: "",
      password: "",
    };
    if (data.phoneNumber === "") {
      valid = false;
      errors.phoneNumber = "Please enter phone number!";
    }
    if (data.otp === "") {
      valid = false;
      errors.otp = "Please verify OTP!";
    }
    if (data.password === "") {
      valid = false;
      errors.password = "Please enter password";
    }

    setFormErrors(errors);
    return valid;
  };

  // const handleOtpVerify = () => {
  //   if (formData.otp === "1234") {
  //     setOtpVerified(true);
  //     formErrors((prevErrors) => ({ ...prevErrors, otp: "" }));
  //     alert("OTP verified successfully!");
  //   } else {
  //     setOtpVerified(false);
  //     formErrors((prevErrors) => ({
  //       ...prevErrors,
  //       otp: "Incorrect OTP. Please try again.",
  //     }));
  //   }
  // };

  const handleOtpSend = () => {
    if (!formData.phoneNumber) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: "Please enter phone number!",
      }));
      return;
    }
    if (!formData.otp) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        otp: "Please enter 4 digit otp!",
      }));
      return;
    }
    setOtpSent(true);
    setOtpVerified(false);
    setFormErrors((prevErrors) => ({ ...prevErrors, otp: "" }));
    alert("OTP sent to your mobile number!");
  };

  const handleOnPasswordReset = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError(null);

    if (!validateInputs(formData)) {
      setIsLoading(false);
      return;
    }

    try {
      console.log(formData);
      props.onShowSuccesMsgText("Password Updated.");
    } catch (err) {
      setError(err.message);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleOnPasswordReset}
      className="flex flex-col justify-center items-start w-full mt-8"
    >
      <Input
        id="phoneNumber"
        placeholder="Enter phone number"
        label="Phone Number:"
        message={otpVerified ? "Verified" : ""}
        messageIcon={
          otpVerified && <FaCheckCircle className="ml-1 text-[#75F94C]" />
        }
        className="w-[550px]"
        value={formData.phoneNumber}
        onChange={handleChange}
        error={formErrors.phoneNumber}
      />

      <div className="flex justify-start items-center mt-4 mb-2">
        <input
          id="otp"
          type="text"
          minLength="4"
          maxLength="4"
          placeholder="_ _ _ _"
          value={formData.otp}
          onChange={handleChange}
          className={`flex justify-center items-center ${
            formErrors.otpError ? "border-red-500" : "border-[#858585]"
          } text-[#858585] text-[20px] w-[90px] h-[50px] rounded-md border-2 border-[#858585] px-4 focus:outline-[#333333] mr-4`}
        />
        {/* {<Button
                text="OK"
                onButtonClick={handleOtpVerify}
                className="rounded-xl font-bold px-4 h-[50px]"
                disabled={otpVerified || !phoneNumber}
              />} */}
        <Button
          text={otpSent ? "Resend" : "Send OTP"}
          onButtonClick={handleOtpSend}
          className="bg-[#858585] text-[#333] rounded-xl font-bold h-[50px]"
          disabled={otpVerified}
        />
      </div>
      {formErrors.otp && (
        <p className="text-[14px] text-[#FF4061] font-medium h-[24px]">
          {formErrors.otp}
        </p>
      )}

      <Input
        id="newPassword"
        placeholder="Enter new password"
        label="New Password:"
        className="w-[550px]"
        value={formData.newPassword}
        onChange={handleChange}
        error={formErrors.newPassword}
      />

      <div className="flex flex-col justify-center items-start w-full mt-10 mb-2">
        <Button
          text={isLoading ? "Loading..." : "Done"}
          className="w-full h-[60px] font-bold text-[24px]"
          // disabled={isLoading || !otpVerified}
        >
          {isLoading && <AiOutlineLoading className="animate-spin ml-2" />}
        </Button>
      </div>
    </form>
  );
};

export default ForgotPasswordForm;
